import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Campo } from 'src/app/core/models/domain/Campo';
import { Grupo } from 'src/app/core/models/domain/Grupo';
import { criarMetaDadosCampo, criarMetaDadosGrupo } from 'src/app/core/modules/custom-form/utils/meta-dados-util';
import { getErrorMessage } from '../util/error-handler';
import { fileSizeValidator, requiredFileType } from '../util/validation-file';

@Component({
  selector: 'app-campo-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})
export class FileComponent implements OnInit {

  // Variáveis do modelo
  @Input()
  campo: Campo;

  @Input()
  grupo: Grupo;

  // Variável onde ficará armanezado o arquivo
  file: File;

  // Formulário do Passo atual
  @Input()
  stepFormGroup: FormGroup;

  // Variável do form do Grupo
  @Input()
  grupoControl: FormGroup;

  // Variável que contém o nome e o arquivo
  campoFileControl: FormGroup;

  campoControl: FormControl = new FormControl(null);

  // Auxiliar para manipulação do arquivo na UI
  campoInputControl: FormControl = new FormControl(null);

  // Div para pré-visualização do arquivo
  imagePreview: string;

  // Variáveis auxiliares
  visualizarArquivo: boolean = false;

  messageError: string;

  // Variáveis para definição do arquivo
  readonly types = ['pdf', 'jpeg', 'jpg', 'doc', 'png', 'docx'];

  readonly size: number = 200000;

  constructor() { }

  ngOnInit(): void {

    // Inicialização do campo
    this.initCampoFile();

    // Validação inicial, poderá ser removida futuramente
    this.validateFile(this.file, this.campoInputControl);

  }

  initCampoFile() {

    let validators = null;

    // Se o campo é obrigatório define o Validator required
    if (this.campo.obrigatorio) {
      this.campoControl.setErrors({ 'required': true });
      this.campoInputControl.setErrors({ 'required': true });
      validators = (this.campo.obrigatorio) ? [Validators.required] : null;
    }

    // Atribui os validadores padrões para os campos
    this.campoControl.setValidators(validators);
    this.campoInputControl.setValidators(validators);
    this.campoInputControl.updateValueAndValidity();

    // Inicializa o grupo onde ficará o nome e o arquivo
    this.campoFileControl = new FormGroup({
      nome: this.campoControl,
      file: new FormControl(null, validators)
    });

    // Adiciona o campo no array ou no grupo do form
    if (this.campo.multiValorado) {
      let formArray = this.grupoControl.controls[this.campo.nome] as FormArray;
      formArray.push(this.campoFileControl);
    } else {
      this.grupoControl.addControl(this.campo.nome, this.campoFileControl);
    }

    this.campoFileControl.metaDados = criarMetaDadosGrupo(this.grupo);
    this.campoControl.metaDados = criarMetaDadosCampo(this.campo);
  }

  // Responsável por tratar a seleção do arquivo pelo usuário
  onFileSelected(event: Event) {

    // Pega o arquivo e verifica sua validade
    this.file = (event.target as HTMLInputElement).files[0];
    const isValid = this.validateFile(this.file, this.campoInputControl);

    if (this.file && isValid) {

      // Atribui o arquivo ao campo e os valores
      this.campoFileControl.patchValue({ file: this.file });
      this.campoFileControl.get('file').updateValueAndValidity();

      this.campoControl.setValue(this.file);

      // Faz a pré-visualização do arquivo
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result.toString();
      };
      reader.readAsDataURL(this.file);

      this.messageError = null;

    } else {

      // Caso o arquivo n for válido reinicializa os campos
      this.campoFileControl.controls['file'].setValue(null);
      this.campoFileControl.controls['file'].updateValueAndValidity();

      this.campoControl.setValue(null);

      if (this.messageError) {
        this.campoInputControl.setErrors({ 'incorrect': true });
      }

    }

    this.campoInputControl.setValue(null);

  }

  // Validação do arquivo e atribuição da mensagem de erro
  validateFile(file: File, campoControl: FormControl): boolean {

    // Se o campo tiver inválido por algum validador padrão, é atribuída a mensagem de erro correspondente
    this.messageError = getErrorMessage(this.campo, this.types, campoControl);

    if (!this.messageError) {

      // Faz a validação do tipo de arquivo bem como o tamanho
      if (requiredFileType(this.types, campoControl, this.campo)) {

        this.messageError = 'Extensão do anexo ' + this.campo.label + ' não suportada. São suportadas as seguintes extensões: ' + this.types.join(', ') + '.'
        this.campoControl.setErrors({ 'incorrect': true });

      } else if (fileSizeValidator(file, this.size, campoControl)) {

        this.messageError = 'Tamanho do anexo ' + this.campo.label + ' excedido. São suportados anexos até 200 mb.';
        this.campoControl.setErrors({ 'incorrect': true });

      }

    }

    return (this.messageError) ? false : true;

  }

  // Método responsável por verificar qual mensagem deverá ser exibida par validação padrão
  getErrorMessage(): string {
    return getErrorMessage(this.campo, this.types, this.campoControl);
  }

}

