import { FormControl } from "@angular/forms";
import { Campo } from "src/app/core/models/domain/Campo";

/*
    Responsável por prover as funções globais para errors fazendo o papel de um handler
*/

// Verificar qual mensagem de erro correspondente ao estado/errors do control
export function getErrorMessage(campo: Campo, types: string[], campoControl: FormControl): string{

        let label = campo.label;
        let errorMessage = null;
    
        if(campoControl.value && campoControl.hasError('type')){
          errorMessage = 'Extensão do anexo ' + campo.label + ' não suportada. São suportadas as seguintes extensões: ' + types.join(', ') + '.'
        }else if (campoControl.value && campoControl.hasError('size')) {
          errorMessage = 'Tamanho do anexo ' + campo.label + ' excedido. São suportados anexos até 200 mb.';
        }else if (campoControl.hasError('requiredTrue') || (campo.tipoComponente=='checkbox' && campoControl.hasError('required'))) {
          errorMessage = 'É obrigatório dar ciência no item acima.';
        } else if (campoControl.hasError('required')) {
          errorMessage = 'O ' + label + ' é obrigatório.';
        }else if (campoControl.hasError('minlength')) {
          errorMessage = 'O ' + label + ' deve ter no mínimo ' +  campo.minLength + ' caracteres.';
        } else if (campoControl.hasError('maxlength')) {
          errorMessage = 'O ' + label + ' deve ter no máximo ' + campo.tamanhoMaximo + ' caracteres.';
        } else if (campoControl.hasError('mask')) {
          errorMessage = 'O valor digitado ' + campoControl.getRawValue() + ' não corresponde a um ' + label + ' válido.';
        } else if (campoControl.hasError('email')) {
          errorMessage = 'O valor digitado ' + campoControl.getRawValue() + ' não corresponde a um ' + label + ' válido.';
        } else if (campoControl.hasError('pattern')) {
          errorMessage = 'O ' +  label + ' é inválido.';
        }
    
        return errorMessage;

}
