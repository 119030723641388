<div class="mt-2 mb-2">
    <div class="card">
        <div class="card-body">

            <div>
                O campo <span class="text-uppercase" style="text-decoration: underline;">{{ campo.label }}</span> aceita mais de uma entrada,
                    para adicionar mais campos desse 

                <ng-container>
                    
                    <a href="" (click)="adicionarCampo(campo);$event.preventDefault();" >Clique Aqui</a>
                    
                </ng-container>                
            </div>

            <div class="row">

                <ng-template #viewContainerRef></ng-template> 

            </div>           

        </div>
    </div>
</div>