<div class="container" *ngIf="grupo?.campos.length > 0">
    <div class="row">

        <div *ngIf="!grupo?.multiValorado && !chamado" class="divhr">
            <p class="fs-4 text-center pt05rem corTituloSobBarra">
                {{ grupo.label }}
            </p>
            <hr class="classhr">
        </div>
        <ng-container *ngFor="let campo of grupo.campos">

            <ng-container *ngIf="campo.visivel">

                <div *ngIf="!campo.multiValorado" [ngClass]="{'col-sm-6' : !campo.multiValorado && !campo.chamaGrupo}"
                    class="pb-2">
                    <app-campo [campo]="campo" [grupo]="grupo" [grupoControl]="grupoControl"
                        [dadosServidor]="dadosServidor" [stepFormGroup]="stepFormGroup"></app-campo>
                </div>

                <div *ngIf="campo.multiValorado" class="pb-2">
                    <app-campo-multivalorado [campo]="campo" [grupo]="grupo" [grupoControl]="grupoControl"
                        [stepFormGroup]="stepFormGroup"></app-campo-multivalorado>
                </div>

            </ng-container>

        </ng-container>
    </div>
</div>