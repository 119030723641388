import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map} from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationRequest } from '../models/authentication/requests/authentication-request';
import { UserResponse } from '../models/authentication/responses/user-response';
import { CryptoService } from './crypto.service';
import { AuthenticationResponse } from '../models/authentication/responses/authentication-response';
import { PerfilResponse } from '../models/authentication/responses/perfil-response';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private userSubject: BehaviorSubject<UserResponse | null>;
    public userResponse: Observable<UserResponse | null>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private cryptoService: CryptoService
    ) {
        
        this.userSubject = new BehaviorSubject((localStorage.token) 
                        ? cryptoService.decrypt(localStorage.token, AuthenticationResponse).userResponse : null);
        this.userResponse = this.userSubject.asObservable();
    }

    public get userValue() {
        return this.userSubject.value;
    }

    public get user() {
        return this.userResponse;
    }

    login(authenticationRequest: AuthenticationRequest) {

        return this.http.post<any>(`${environment.apiUrlUsuario}/usuario/autenticar`, 
                        { identificador: authenticationRequest.identificador, senha: authenticationRequest.senha })
            .pipe(
                map(authResponseData => {

                    //Encripta o token para maior segurança para o armazenamento
                    let encryptedAuthData = this.cryptoService.encrypt(authResponseData);

                    // armazena o token no localStorage
                    localStorage.setItem('token', encryptedAuthData);

                    const authResponseDataObject = new AuthenticationResponse(authResponseData);
                    this.userSubject.next(authResponseDataObject.userResponse);

                    let perfis = authResponseData.userResponse?.perfis?.map((p: PerfilResponse) => p.nome);
                    this.userSubject.value.isAdmin = perfis.includes('admin');

                   authResponseData.userResponse.papeis = authResponseData.userResponse.papeis.filter((item: { categoria: string }) => item.categoria !== 'ALUNO');
 

                    return authResponseData;
                })
            );

    }

    logout() {

        // remove o token do localStorage
        localStorage.removeItem('token');
        this.userSubject.next(null);

        // Redireciona para o login
        this.router.navigate(['/login']);

    }
}