import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenExternalUser } from 'src/app/core/models/token/token-external-user';
import { TokenService } from 'src/app/core/services/token.service';

@Component({
  selector: 'app-reenvio-token',
  templateUrl: './reenvio-token.component.html',
  styleUrls: ['./reenvio-token.component.css']
})
export class ReenvioTokenComponent {
  
  msgSuccess: string = '';
  msgFailure: string = '';
  
  constructor(private tokenService: TokenService){

  }

  reenvioForm = new FormGroup({
    numeroProtocolo: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    cpf: new FormControl('', [Validators.required]),
  });

  get form(): any {
    return this.reenvioForm.controls;
  }

  onSubmit(): void {

    let tokenExternalRequest: TokenExternalUser = {
      numberContext: this.reenvioForm.value.numeroProtocolo as string,
      email: this.reenvioForm.value.email as string,
      cpf: this.reenvioForm.value.cpf as string
    }

    this.tokenService.generateTokenExternalUser(tokenExternalRequest)
    .subscribe({
      next: (response) => {
        this.msgSuccess = response.message;
      },
      error: (e) => {
        this.msgFailure = 'Erro ao gerar o acesso para o usuário.';
      }
    });
 
    //this.form.reset();
  }

}
