import { Injectable } from '@angular/core';
import { TokenExternalUser } from '../models/token/token-external-user';
import { Observable } from 'rxjs';
import { TokenExternalUserResponse } from '../models/token/token-external-user-response';
import { Token } from '@angular/compiler';
import { environment } from 'src/environments/environment';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  generateTokenExternalUser(request: TokenExternalUser): Observable<TokenExternalUserResponse>{

    return new Observable<TokenExternalUserResponse>(observer => {
      axios.post<TokenExternalUserResponse>
        (`${environment.apiUrlUsuario}/token/generateTokenExternalUser`, request)
        .then(response => {
          observer.next(response.data);
          observer.complete();
        })
        .catch(e => {
          observer.error(e);
        });
    });
    
  };

}
