import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { AuthenticationResponse } from '../models/authentication/responses/authentication-response';
import { CryptoService } from '../services/crypto.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService, private cryptoService: CryptoService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const token = localStorage.token;

    let bearerToken = '';
    if(token){
      const authenticationResponse = this.cryptoService.decrypt(token, AuthenticationResponse);
      bearerToken = authenticationResponse.accessToken;
    }

    const domainApi = environment.domainApi;
    const isApiUrl = request.url.includes(domainApi);

    if(bearerToken && isApiUrl) {
      request = request.clone({
        headers : request.headers.append('Authorization', `Bearer ${bearerToken}`)
      });
    }

    return next.handle(request);
  }
}
