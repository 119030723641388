import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-conclusao-abertura-requerimento',
  templateUrl: './conclusao.component.html',
  styleUrls: ['./conclusao.component.css']
})
export class ConclusaoComponent implements OnInit {

  numeroProtocolo: string;

  constructor(public router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.numeroProtocolo = this.activatedRoute.snapshot.params['numeroProtocolo'];
  }

}
