import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user';
import { UserResponse } from '../../models/authentication/responses/user-response';
import { AuthenticationHelper } from '../../helpers/authentication.helper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  loading = false;
  userResponse: UserResponse;
  userFromApi?: User;
  isAdmin = false;

  constructor(
      private userService: UserService,
      private authenticationService: AuthenticationService,
      private authenticationHelper: AuthenticationHelper
  ) {
      this.userResponse = <UserResponse>this.authenticationService.userValue;
      this.isAdmin = authenticationHelper.isAdmin();
      
  }

  ngOnInit() {
      this.loading = true;
      this.userService.getByCpf(this.userResponse.cpf).pipe(first()).subscribe(user => {
          this.loading = false;
          this.userFromApi = user;
      });
  }

}