<div class="container w-100" id="areaBusca">
    <form #formConsulta="ngForm" [formGroup]="reenvioForm" (ngSubmit)="onSubmit()" class="d-flex justify-content-center">
        <div class="card shadow w-50">
            <div class="card-body m-3">
                <div>
                    <p class="h3 fw-bold p-0">Reenvio de Token</p>
                </div>
                <div>
                    <p class="p-0">Preencha os dados para reenvio de acesso pro usuário:</p>
                </div>
                <div class="alert alert-success" *ngIf="msgSuccess" role="alert">
                    Envio realizado com sucesso! <br> Em instantes o usuário receberá um email com o link para acesso.
                </div>                                
                <div>
                        <label for="numeroProtocolo" class="form-label p-0">Número de Protocolo</label>
                        <input type="text" class="form-control" id="numeroProtocolo" formControlName="numeroProtocolo">
                        <div class="alert alert-danger p-2 " *ngIf="form.numeroProtocolo.touched && form.numeroProtocolo.invalid" role="alert">
                            <span *ngIf="form.numeroProtocolo.errors.required">
                                Por favor, informe o número do Protocolo.
                            </span>
                        </div>
                </div>
                <div>
                    <label for="email" class="form-label p-0">E-mail</label>
                    <input type="text" class="form-control" id="email" formControlName="email">
                    <div class="alert alert-danger p-2" *ngIf="form.email.touched && form.email.invalid" role="alert">
                        <span *ngIf="form.email.errors.required">
                            Por favor, informe o email.
                        </span>
                        <span *ngIf="form.email.errors.email">
                            Email em formato errado.
                        </span>                        
                    </div>                    
                </div>    
                <div>
                    <label for="cpf" class="form-label p-0">CPF</label>
                    <input type="text" class="form-control" id="cpf" mask="000.000.000-00" minlength="11" formControlName="cpf">
                    <div class="alert alert-danger p-2" *ngIf="form.cpf.touched && form.cpf.invalid" role="alert">
                        <span *ngIf="form.cpf.errors.required || form.cpf.errors.minlength">
                            Por favor, informe o cpf.
                        </span>                                        
                    </div>                      
                </div>    
                <div class="text-center p-2">
                    <div class="col-12">
                        <button mat-flat-button class="me-3" color="primary" type="submit"
                            [disabled]="!reenvioForm.valid">Enviar acesso</button>                                           
                    </div>
                </div>
                
            </div>
        </div>
    </form>
</div>
        