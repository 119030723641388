import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationHelper } from '../../helpers/authentication.helper';
import { RequerimentoService } from '../../services/requerimento.service';

declare var Tablesort: any;

@Component({
  selector: 'app-requerimento',
  templateUrl: './requerimento.component.html',
  styleUrls: ['./requerimento.component.css']
})
export class RequerimentoComponent implements AfterViewInit {

  id: string;
  requerimento: any;
  sortedData: any;
  dataSource: any;
  logado: boolean;

  authHelper: AuthenticationHelper;

  constructor(private route: ActivatedRoute, private requerimentoService: RequerimentoService, private aHelper: AuthenticationHelper) {
    this.authHelper = aHelper;
    this.logado = this.authHelper.logado();

  }


  ngOnInit() {

    this.logado = this.authHelper.logado();

    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    this.requerimentoService.get(this.id).subscribe(data => {
      this.requerimento = data;
      this.requerimento.dataAbertura = this.requerimento.dataAbertura.slice(0, 19);

    });


  }


  ngAfterViewInit(): void {
    this.sortedData = this.requerimento.historico;
    this.dataSource = this.requerimento.historicos;
    console.log(this.dataSource);

  }







}
