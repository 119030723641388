<div mat-dialog-title>Alteração do Nº SEI</div>
<div mat-dialog-content class="mat-typography" align="center">

      <form class="form" #form="ngForm">        
        <mat-form-field appearance="fill" class="input-full-width">
          <mat-label>Nº SEI:</mat-label>

          <input matInput [(ngModel)]="numeroSEI" name="numeroSEI" maxlength="18" mask="000000/000000/0000" required/>
          <mat-error *ngIf="!this.numeroSEI">O número SEI é obrigatório.</mat-error>
        </mat-form-field>
      </form>

</div>


<div mat-dialog-actions>

  <div class="mb-3">
    <button class="btn btn-success green-button mx-2"  (click)="editarNumeroSEI()">Salvar<i class="bi bi-check"></i></button>
    <button class="btn btn-secondary mx-2" [mat-dialog-close]="null" data-bs-dismiss="modal">Fechar</button>
  </div>

</div>
