import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { EnumStatusRequerimento } from 'src/app/core/models/domain/EnumStatusRequerimento';
import { Requerimento } from 'src/app/core/models/domain/requerimento-class.model';
import { RequerimentoService } from 'src/app/core/services/requerimento.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, first, map, startWith } from 'rxjs';

import { DialogConfirmacaoComponent } from './dialogs/dialog-confirmacao/dialog-confirmacao.component';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { DialogValidacaoComponent } from './dialogs/dialog-validacao/dialog-validacao/dialog-validacao.component';

import { TipoRequerimentoService } from 'src/app/core/services/tipo.requerimento.service';
import { TipoRequerimento } from 'src/app/core/models/domain/TipoRequerimento';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { UserResponse } from 'src/app/core/models/authentication/responses/user-response';
import { User } from 'src/app/core/models/user';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { UserService } from 'src/app/core/services/user.service';



export interface Dados {
  requerimento: string;
  matricula: string;
  status: string;
  tipo: string;
  numeroProtocolo: string;
  numeroSEI: string;
}

@Component({
  selector: 'app-requerimento-data-table',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DataTableComponent implements OnInit, AfterViewInit {

   // Filters

   filterForm: FormGroup;
   requerimento: any;
   matricula: any;
   status: any;
   tipo: any;
   protocolo: any;
   numeroSEI: any;
   cpf: any;
   tipoRequerimentoInput: any;
   tipoRequerimento: string;
   dataInicio: any;
   dataFim: any;
   myControl = new FormControl('');
   options: TipoRequerimento[] = [];
   filteredOptions: Observable<TipoRequerimento[]>;
   listaRequerimentos: Requerimento[] = [];

   loading = false;
   userResponse: UserResponse;
   userFromApi?: User;
   

  constructor(private formBuilder: FormBuilder, private requerimentoService: RequerimentoService, 
          private tipoRequerimentoService: TipoRequerimentoService, public dialog: MatDialog, 
          private dateAdapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: String, private userService: UserService,
          private authenticationService: AuthenticationService) {
    //this.sortedData = this.requerimentos;
    this.enumStatus = EnumStatusRequerimento.ABERTO;
    this.userResponse = <UserResponse>this.authenticationService.userValue;

    // Filter
    this.filterForm = this.formBuilder.group({
      requerimento: [''],
      matricula: [''],
      status: [''],
      tipo: [''],
      numeroProtocolo: [''],
      protocolo: [''],
      numeroSEI: [''],
      cpf: [''],
      tipoRequerimento: [''],
      dataInicio: [''],
      dataFim: ['']
    });
  }
  

  // DataTable

  @ViewChild('acaoColDef') acaoColDef: ElementRef | undefined;

  displayedColumns: string[] = ['requerimento', 'matricula', 'cpfRequisitado', 'status', 'tipo', 'numeroProtocolo', 'numeroSEI', 'dataAbertura','acao'];
  acoes: string[] = ['Cancelar', 'Validar','Editar', 'Finalizar'];
  //sortedData: Requerimento[] = [];
  @Input()
  requerimentos: Requerimento[] = [];
  tiposRequerimento: TipoRequerimento[] = [];

  //dataSource = this.requerimentos;
  dataSource = new MatTableDataSource<Requerimento>;
  //itens: MatTableDataSource<Requerimento[]> = new MatTableDataSource<any[]>([]);
  //itens: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
  sortedData = this.dataSource;
  enumStatus: EnumStatusRequerimento;

  @ViewChild('table') table: MatTable<Requerimento>;
  @ViewChild(MatPaginator) paginator: MatPaginator;




  ngOnInit(): void {
    
    $('#dataInicio').mask('99/99/9999');
    $('#dataFim').mask('99/99/9999');

    this.loading = true;


    console.log(">>>"+this.userResponse.cpf);
    

    this.dateAdapter.setLocale(this._locale);

    this.tipoRequerimentoService.findAll().subscribe(data => {
      this.options = [new TipoRequerimento(null, null, "Todos", null, null, null, null), ...data];
    });

    // Initiation filters
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

    // Requerimentos
    this.listarRequerimentos();

  }

  ngAfterViewInit(): void {
    this.table.renderRows();
    this.criarPaginator();

  }

  dispatch(input: HTMLInputElement) {
    input.dispatchEvent(new Event("input"));
  }

  criarPaginator(){
    const customPaginatorIntl = new MatPaginatorIntl();
    customPaginatorIntl.itemsPerPageLabel = 'Requisitos por página:';
    customPaginatorIntl.firstPageLabel = 'Primeira página';
    customPaginatorIntl.nextPageLabel = 'Página seguinte';
    customPaginatorIntl.previousPageLabel = 'Página anterior';
    customPaginatorIntl.lastPageLabel = 'Última página';
    customPaginatorIntl.getRangeLabel = function (page: number, pageSize: number, length: number) {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `Exibindo ${start} a ${end > length ? length : end} de ${length} itens`;
    };
    this.paginator._intl = customPaginatorIntl;
  }

  sortData(sort: Sort) {
    const data = this.dataSource;
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

  }

  openDialog(acao: string, id: string, element: any) {
    console.log(['openDialog'], element);
    if (acao == "Cancelar") {
      const dialogRef = this.dialog.open(DialogConfirmacaoComponent);
      dialogRef.componentInstance.acao = "cancelar";

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.requerimentoService.cancel({id : id}).subscribe(() => {
            this.listarRequerimentos();
          });
        }
      });

    }

    if (acao == "Finalizar") {
      const dialogRef = this.dialog.open(DialogConfirmacaoComponent);
      dialogRef.componentInstance.acao = "finalizar";

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.requerimentoService.close(id, element).subscribe(() => {
            this.listarRequerimentos();
          });
        }
      });
    }

    if (acao == "Validar") {

      const dialogRef = this.dialog.open(DialogValidacaoComponent);
      dialogRef.componentInstance.id = id;
      dialogRef.componentInstance.protocolo = element.numeroProtocolo;
      dialogRef.afterClosed().subscribe(result => {
        //console.log(result != null);
        this.listarRequerimentos();
        this.table.renderRows();
      });

    }

  }

  listarRequerimentos() {
    this.requerimentoService.findAll().subscribe(data => {
      this.requerimentos = data;
      this.listaRequerimentos = data;
      this.dataSource = new MatTableDataSource<Requerimento>(data);
      this.dataSource.paginator = this.paginator;
      console.log(['listaRequerimentos'], this.listaRequerimentos)
      console.log(['filterForm'], this.filterForm)
    });

    
  }

  // Filters
  private _filter(value: string): any[] {
    const filterValue = (typeof value === 'string') ? value.toLowerCase() : this.requerimento;

    if (filterValue === null && this.requerimento === null) {
      return null;
    }

    return this.options.filter((option) =>
      option.nome.toLowerCase().includes(filterValue)
    );
  }

  get f() { return this.filterForm.controls; }

  filtrar() {
    

  const body = {
    matriculaRequisitado: this.f.matricula.value?.trim() || null,
    numeroProtocolo: this.f.protocolo.value?.trim() || null,
    status: this.f.status.value ? { id: this.f.status.value } : null,
    tipoRequerimento: { id: this.tipoRequerimentoInput },
    numeroSEI: this.f.numeroSEI.value?.trim() || null,
    cpfRequerente: this.f.cpf.value?.trim() || null,
    dataInicio: this.f.dataInicio.value || null,
    dataFim: this.f.dataFim.value || null
  };

    this.buscarRequerimento(body);
    this.table.renderRows();
  }

  buscarRequerimento(busca: any) {
    this.requerimentoService.search(busca).subscribe(data => {
      //this.requerimentos = data;
      this.dataSource = new MatTableDataSource<Requerimento>(data);
      this.table.renderRows();
      this.dataSource.paginator = this.paginator;
    });
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.tipoRequerimentoInput = event && event.option && event.option.value && event.option.value.id ? event.option.value.id.toString() : null;
    this.tipoRequerimento = event && event.option && event.option.value && event.option.value.nome ? event.option.value.nome : null;
  }

  @ViewChild(MatAutocompleteTrigger) autoComplete: MatAutocompleteTrigger;

  resetForm() {
    this.tipoRequerimentoInput = null;
    this.tipoRequerimento = null; // ou '' se preferir
    this.autoComplete.closePanel();
  }

  /*listarAcoes(status: any) {
    let listaAcoes : Array<string>;

    return listaAcoes;
  } */

  listarAcoes(status: number): string[] {
    let listaAcoes: string[] = [];

    if (status === EnumStatusRequerimento.ABERTO) {
      listaAcoes.push("Validar","Editar","Cancelar");
    } else if (status === EnumStatusRequerimento.VALIDADO) {
      listaAcoes.push("Criar", "Cancelar");
    } else if (status === EnumStatusRequerimento.PROCESSO_CRIADO) {
      listaAcoes.push("Finalizar", "Cancelar");
    }

    return listaAcoes;
  }

}