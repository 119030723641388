import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardGuard{

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const user = this.authenticationService.userValue;

      if(user) {

        // verifica se o usuário possui perfil para acessar
        const { roles } = route.data;

        let perfis = user?.perfis.map(p => p.nome);

        if(roles && !roles.some(perfis)) {
          this.router.navigate(['/']);
          return false;
        }

        return true;
      }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
    return false;
  }
  
  
}
