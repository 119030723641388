<h1 mat-dialog-title>{{acao}} requerimento</h1>



<div mat-dialog-content>
  Deseja {{acao}} esse requerimento?

  <mat-form-field style="width: 100%;" *ngIf="acao == 'cancelar'">
    <mat-label>Observações:</mat-label>
    <textarea matInput style="height: 200px;" [(ngModel)]="observacao"></textarea>
    <div class="error-message" *ngIf="observacaoError">{{observacaoErrorMessage}}</div>
  </mat-form-field>
  
  

</div>


<div mat-dialog-actions>
  <button class="btn btn-primary me-3" [mat-dialog-close]="true" cdkFocusInitial *ngIf="acao != 'cancelar'" >Sim</button>
  <button class="btn btn-primary me-3" (click)="fecharDialogComObservacao()" *ngIf="acao == 'cancelar'">Confirmar</button>
  <button class="btn btn-danger" mat-dialog-close>Não</button>
</div>