import { EnumStatusRequerimento } from "./EnumStatusRequerimento";
import { Historico } from "./Historico";
import { TipoRequerimento } from "./TipoRequerimento";

export class Requerimento {

    id: number;
    acessosExternos: Array<string>;
    tipoRequerimento: TipoRequerimento;
    numeroProtocolo: string;
    numeroSEI: string;
    status: EnumStatusRequerimento;
    cpfRequerente: string;
    cpfRequisitado: string;
    matriculaRequisitado: string;
    historicos: Array<Historico>;
    

    constructor(id: number){
        this.id = id
    }

    /*constructor(
        id: number,
        acessosExternos:  Array<string>,
        tipoRequerimento: TipoRequerimento,
        numeroProtocolo: string,
        numeroSEI: string,
        status: EnumStatusRequerimento,
        cpfRequerente: string,
        cpfRequisitado: string,
        matriculaRequisitado: string,
        historicos: Array<Historico>
    ) {
        this.id = id
        this.acessosExternos = acessosExternos
        this.tipoRequerimento = tipoRequerimento
        this.numeroProtocolo = numeroProtocolo
        this.numeroSEI = numeroSEI
        this.status = status
        this.cpfRequerente = cpfRequerente
        this.cpfRequisitado = cpfRequisitado
        this.matriculaRequisitado = matriculaRequisitado
        this.historicos = historicos
    } */

}
