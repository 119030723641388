import { NgModule } from '@angular/core';

//pages
import { AberturaRequerimentoComponent } from './core/pages/requerimentos/abertura-requerimento/abertura-requerimento.component';
import { IndexComponent } from './core/pages/requerimentos/index/index.component';

import { RouterModule, Routes } from '@angular/router';
import { DataTableComponent } from './core/components/gestao/datatable/datatable.component';
import { FilterComponent } from './core/components/gestao/filter/filter.component';
import { GestaoComponent } from './core/components/gestao/gestao.component';
import { DashComponent } from './layouts/dash/dash.component';
//import { HomeComponent } from './shared/components/home/home.component';
import { AjudaComponent } from './core/components/ajuda/ajuda.component';
//import { AutheticationComponent } from './core/components/authetication/authetication.component';
import { ConsultaRequerimentoComponent } from './core/components/consulta-requerimento/consulta-requerimento.component';
import { HomeComponent } from './core/components/home/home.component';
import { InicioExternoComponent } from './core/components/inicio-externo/inicio-externo.component';
import { InicioComponent } from './core/components/inicio/inicio.component';
import { LoginComponent } from './core/components/login/login.component';
import { PerfilComponent } from './core/components/perfil/perfil.component';
import { AberturaRequerimentoRefComponent } from './core/components/requerimento/abertura/abertura.component';
import { ConclusaoComponent } from './core/components/requerimento/abertura/conclusao/conclusao.component';
import { InicioAberturaRequerimentoComponent } from './core/components/requerimento/abertura/inicio/inicio.component';
import { StepperAberturaRequerimentoComponent } from './core/components/requerimento/abertura/stepper/stepper.component';
import { RequerimentoComponent } from './core/components/requerimento/requerimento.component';
import { ReenvioTokenComponent } from './core/components/token/reenvio-token/reenvio-token.component';
import { AuthenticationGuardGuard } from './core/guards/authentication-guard.guard';
import { Role } from './core/models/role';
import { ConsultaExterna } from './core/pages/requerimentos/consulta-requerimento-externa/index/consulta-externa.component';


const routes: Routes = [
  { path: 'filters', component: FilterComponent },
  { path: 'tabela', component: DataTableComponent },
  {
    path: '',
    component: DashComponent,
    children: [
      { path: '', component: InicioComponent },
    ],
  },
  {
    path: 'dashboard',
    component: DashComponent,
    children: [
      { path: '', component: HomeComponent },
    ],
    canActivate: [AuthenticationGuardGuard]
  },
  {
    path: 'perfil',
    component: DashComponent,
    children: [
      { path: '', component: PerfilComponent },
    ],
    canActivate: [AuthenticationGuardGuard]
  },
  {
    path: 'requerimento',
    component: DashComponent,
    children: [
      { path: 'abertura', component: AberturaRequerimentoComponent },
      { path: 'consulta', component: ConsultaRequerimentoComponent },
      { path: 'detalhes/:id', component: RequerimentoComponent }, // <-- Aqui está o parâmetro "id"
    ],
    //TODO: verificar se todas as rotas precisam ser mesmo restritas
    //canActivate: [AuthenticationGuardGuard]
  },
  // rota de refatoração
  {
    path: 'abertura',
    component: DashComponent,
    children: [
      {
        path: '', component: AberturaRequerimentoRefComponent,
        children: [
          { path: 'inicio', component: InicioAberturaRequerimentoComponent, outlet: 'abertura' },
          { path: 'step/:data', component: StepperAberturaRequerimentoComponent, outlet: 'abertura' },
          { path: 'conclusao/:numeroProtocolo', component: ConclusaoComponent, outlet: 'abertura' }
        ]
      },

    ],
  },
  {
    path: 'requerimentos',
    component: DashComponent,
    children: [

      { path: '', component: IndexComponent },
    ],
    //canActivate: [AuthenticationGuardGuard]
  },
  {
    path: 'consulta-externa',
    component: DashComponent,
    children: [

      { path: '', component: ConsultaExterna },
    ],
    //canActivate: [AuthenticationGuardGuard]
  },
  {
    path: 'requerimentos-antigo',
    component: DashComponent,
    children: [
      { path: '', component: GestaoComponent },
    ],
    canActivate: [AuthenticationGuardGuard]
  },

  {
    path: 'ajuda',
    component: DashComponent,
    children: [

      { path: '', component: AjudaComponent },
    ]
  },

  {
    path: '',
    component: DashComponent,
    children: [
      { path: '', component: HomeComponent },
    ],
    canActivate: [AuthenticationGuardGuard]
  },
  {
    path: 'admin',
    component: GestaoComponent,
    canActivate: [AuthenticationGuardGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'inicio',
    component: DashComponent,
    children: [

      { path: 'externo', component: InicioExternoComponent },
    ]
  },

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'reenvio-token',
    component: DashComponent,
    children: [

      { path: '', component: ReenvioTokenComponent },
    ],
    canActivate: [AuthenticationGuardGuard]
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
