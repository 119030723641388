  <div class="card-wrapper">

    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>Lista de requerimentos</mat-card-title>
        <mat-card-subtitle>
          Requerimentos Admin
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="requerimentos">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Id </th>
                <td mat-cell *matCellDef="let requerimento"> {{requerimento.id}} </td>
              </ng-container>
              <ng-container matColumnDef="descricao">
                <th mat-header-cell *matHeaderCellDef> Descricao </th>
                <td mat-cell *matCellDef="let requerimento"> {{requerimento.descricao}} </td>
              </ng-container>
              
              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
          </table>
      </mat-card-content>
  
      <mat-card-actions>
        <button mat-raised-button color="accent">Fechar</button>
        <button mat-raised-button color="primary">Validar</button>
      </mat-card-actions>
    </mat-card>
  
  </div>
