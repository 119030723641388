<!-- Filters -->


<div class="card-wrapper shadow-lg">
  <mat-card class="card" style="padding: 10px">
    <form class="form" #form="ngForm" [formGroup]="filterForm">
      <mat-grid-list cols="1" rowHeight="70px">
        <mat-grid-tile>
          <mat-label>Matrícula </mat-label>
          <mat-form-field>
            <input matInput type="text" [formControlName]="'matricula'" />
          </mat-form-field>
          <mat-label>Status </mat-label>
          <mat-form-field>
            <mat-select [formControlName]="'status'">
              <mat-option value="1">Aberto</mat-option>
              <mat-option value="2">Validado</mat-option>
              <mat-option value="5">Cancelado</mat-option>
              <mat-option value="3">Processo criado</mat-option>
              <mat-option value="4">Finalizado</mat-option> 
            </mat-select>
          </mat-form-field>
          <mat-label>Tipo </mat-label>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Tipo de requerimento</mat-label>
            <input type="text" placeholder="Selecione um" aria-label="Tipo de requerimento" matInput
              [formControl]="myControl" [matAutocomplete]="auto" [formControlName]="'tipoRequerimento'" />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
              (optionSelected)="onSelectionChanged($event)">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{ option.nome }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="1" rowHeight="70px">
        <mat-grid-tile>
          <mat-label>Número de Protocolo </mat-label>
          <mat-form-field>
            <input matInput type="text" [formControlName]="'protocolo'" />
          </mat-form-field>
          <mat-label>Número SEI </mat-label>
          <mat-form-field>
            <input matInput type="text" [formControlName]="'numeroSEI'" width="50%" />
          </mat-form-field>

          <mat-label>CPF </mat-label>
          <mat-form-field>
            <input matInput type="text" [formControlName]="'cpf'" width="50%" />
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="1" rowHeight="70px">
        <mat-grid-tile>
          <mat-label>Início </mat-label>
          <mat-form-field color="accent" appearance="fill">
            <input matInput [matDatepicker]="picker1"   [formControlName]="'dataInicio'" id="dataInicio"/>
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-label>Fim </mat-label>
          <mat-form-field color="accent" appearance="fill">
            <input matInput [matDatepicker]="picker2" [formControlName]="'dataFim'" id="dataFim"/>
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <br>
      <div class="button-row">
        <button mat-raised-button color="primary" (click)="filtrar()">
          Filtrar
        </button>
        <button mat-raised-button color="warn" type="reset" (click)="resetForm()">Limpar</button>
      </div>
    </form>
  </mat-card>

  <!-- {{ dataSource }} -->
  <!--DataTable-->
  <table matSort mat-table #table [dataSource]="dataSource" class="mat-elevation-z8" style="margin-left: 0px; ">

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="requerimento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="requerimento">Requerimento</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="matricula">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="matriculaRequisitado">Matrícula</th>
      <td mat-cell *matCellDef="let element">{{ element.matriculaRequisitado }}</td>
    </ng-container>

    <ng-container matColumnDef="cpfRequisitado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="cpfRequisitado">CPF</th>
      <td mat-cell *matCellDef="let element">{{ element.cpfRequisitado }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status.descricao }}</td>
    </ng-container>

    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="tipo">Tipo</th>
      <td mat-cell *matCellDef="let element">{{ element.tipoRequerimento.nome }}</td>
    </ng-container>

    <ng-container matColumnDef="numeroProtocolo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="numeroProtocolo">Número de Protocolo</th>
      <td mat-cell *matCellDef="let element">{{ element.numeroProtocolo }}</td>
    </ng-container>

    <ng-container matColumnDef="dataAbertura">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="dataAbertura">Data Abertura</th>
      <td mat-cell *matCellDef="let element">{{ element.dataAbertura | date: 'dd/MM/yyyy'}}</td>
    </ng-container>


    <ng-container matColumnDef="numeroSEI">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="numeroSEI">Número SEI</th>
      <td mat-cell *matCellDef="let element">{{ element.numeroSEI }}</td>
    </ng-container>

    <ng-container matColumnDef="acao" #acaoColumnDef>

      <th mat-header-cell *matHeaderCellDef>Ação</th>
      <td mat-cell *matCellDef="let element">

        <tr *ngFor="let acao of listarAcoes(element.status.id) ">
          <button mat-button (click)="openDialog(acao,element.id,element)">{{acao}}</button>
        </tr>

      </td>
    </ng-container>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons
    aria-label="Select page of periodic elements">
  </mat-paginator>