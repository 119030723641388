import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { Requerimento } from 'src/app/core/models/domain/requerimento-class.model';
import { RequerimentoService } from 'src/app/core/services/requerimento.service';

@Component({
  selector: 'app-requerimento-list',
  templateUrl: './requerimento-list.component.html',
  styleUrls: ['./requerimento-list.component.css']
})
export class RequerimentoListComponent implements OnInit {
  loading = false;
  requerimentos: Requerimento[] = [];
  columnsToDisplay = ['id', 'descricao'];

  constructor(private requerimentoService: RequerimentoService) {

  }

  ngOnInit(): void {
    this.requerimentoService.findAll().pipe(first()).subscribe(data => {
      this.loading = false;
      this.requerimentos = data;
    });
  }

}
