<div class="mt-2 mb-2">
  <div class="card">
    <div class="card-body">

      <div class="divhr mt-4 mb-4" *ngIf="!chamado">
        <p class="fs-4 text-center pt05rem corTituloSobBarra">
          {{ grupo.label }}
        </p>
        <hr class="classhr">
      </div>

      <ng-container *ngIf="!checkStatus('PREENCHIMENTO');">
        O grupo <span class="text-uppercase grupoLabel">{{ grupo.label }}</span> aceita mais de um registro,
        para adicionar

        <ng-container>

          <a href="" (click)="adicionarGrupo();$event.preventDefault();">Clique Aqui</a>

        </ng-container>
      </ng-container>

      <p *ngIf="checkStatus('PREENCHIMENTO')">Preencha os campos do formulário abaixo para inclusão de um novo registro
        no grupo <span class="grupoLabel">{{ grupo?.label }}</span>:</p>

      <ng-template #viewContainerRef>

      </ng-template>

      <div *ngIf="checkStatus('PREENCHIMENTO');" class="mt-2 mb-2">

        <div *ngIf="grupoArray?.errors?.['minQtdGrupo'] && !submited" class="mt-3 col-6 alert alert-danger"
          role="alert">
          O registro não foi incluído, favor preencher o formulário e clicar em "Adicionar".
        </div>

        <div class="d-inline m-2">
          <button mat-raised-button (click)="adicionar()" matTooltip="Adicionar" [disabled]="formGroupAtual?.invalid">
            Adicionar
          </button>
        </div>
        <div *ngIf="dataSource.length > 0" class="d-inline m-2 divButtonCancelar">
          <button mat-raised-button (click)="cancelar()" matTooltip="Cancelar Inclusão">
            Cancelar
          </button>
        </div>
        <div class="d-inline">
          <a href="" (click)="resetarForm();$event.preventDefault();" matTooltip="Limpar dados do formulário">Limpar
            Campos</a>
        </div>

        <div *ngIf="formGroupAtual?.invalid && submited" class="mt-3 col-6 alert alert-danger" role="alert">
          O formulário contém erros no preencimento dos campos.
        </div>

      </div>

      <div class="mt-2">

        <div *ngIf="checkStatus('INCLUIDO') && !excluidoRegistro" class="col-6 alert alert-success" role="alert">
          Registro incluído com sucesso!
        </div>

        <div *ngIf="excluidoRegistro" class="col-6 alert alert-danger" role="alert">
          Registro excluído com sucesso!
        </div>

        <ng-container *ngIf="!checkStatus('INICIO') && dataSource.length > 0;">

          <table mat-table [dataSource]="dataSource">
            <ng-container [matColumnDef]="column.nome" *ngFor="let column of columns">
              <th mat-header-cell *matHeaderCellDef> {{column.label}} </th>
              <td mat-cell *matCellDef="let element"> {{element[column.nome]}} </td>
            </ng-container>

            <ng-container matColumnDef="acoes">
              <th mat-header-cell *matHeaderCellDef>Ações</th>
              <td mat-cell *matCellDef="let row">
                <button mat-mini-fab color="primary" (click)="removerRegistro(row)"
                  matTooltip="Excluir Registro"><mat-icon>delete_forever</mat-icon></button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="mt-3">
            <p><span style="font-weight: bold;">Observação:</span> Para edição de um registro é necessário excluir o
              mesmo e inseri-lo novamente.</p>
          </div>

        </ng-container>

      </div>

    </div>
  </div>
</div>