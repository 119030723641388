import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInteceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError(err => {

        // Verifica se o erro é Unathorized ou Forbidden quando retornado das APIs

        if([401, 403].includes(err.status) && this.authenticationService.userValue) {
          this.authenticationService.logout();
        }

        //const error = err.error.errors[0] || err.statusText;
        return throwError(() => err);
      }
    ));
  }
}
