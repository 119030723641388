import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Campo } from 'src/app/core/models/domain/Campo';
import { Grupo } from 'src/app/core/models/domain/Grupo';
import { criarMetaDadosCampo } from 'src/app/core/modules/custom-form/utils/meta-dados-util';
import { dateValidator } from '../util/validation-file';

@Component({
  selector: 'app-campo-period-calendar',
  templateUrl: './period-calendar.component.html',
  styleUrls: ['./period-calendar.component.css']
})
export class PeriodCalendarComponent implements OnInit {

  // Variáveis do modelo
  @Input()
  campo: Campo;

  @Input()
  grupo: Grupo;

  // Variáveis do modelo
  @Input()
  campoControl: FormControl;

  // Formulário do Passo atual
  @Input()
  stepFormGroup: FormGroup;

  // Variável do form do Grupo
  @Input()
  grupoControl: FormGroup;

  dataInicioControl: FormControl = new FormControl(null);

  dataFimControl: FormControl = new FormControl(null);

  messageError: string;

  readonly dateFormat = 'DD/MM/YYYY';

  constructor() { }

  ngOnInit(): void {
    this.initCampoPeriodCalendar();
  }

  // Inicia o campo
  initCampoPeriodCalendar() {

    let validators = [Validators.pattern(this.campo.expressaoRegular)];

    // Se o campo é obrigatório define o Validator required
    if (this.campo.obrigatorio) {
      this.campoControl.setErrors({ 'required': true });
      validators.push(Validators.required);

      this.dataInicioControl.setValidators([dateValidator()]);
      this.dataFimControl.setValidators([dateValidator()]);

    }

    // Atribui os validadores padrões para os campos
    this.campoControl.setValidators(validators);

    // Adicionando os campos dataInicio e dataFim no Grupo de controles
    this.grupoControl.addControl(this.campo.nome + '_startDate', this.dataInicioControl);
    this.grupoControl.addControl(this.campo.nome + '_endDate', this.dataFimControl);

    this.campoControl.metaDados = criarMetaDadosCampo(this.campo);

  }

  validarDatas() {

    if (this.dataInicioControl.value || this.dataFimControl.value) {

      let valorInicio = '';
      let valorFim = '';

      if (moment.isMoment(this.dataInicioControl.value)) {
        valorInicio = moment(this.dataInicioControl.value).format(this.dateFormat);
      }

      if (moment.isMoment(this.dataFimControl.value)) {
        valorFim = moment(this.dataFimControl.value).format(this.dateFormat);
      }

      this.campoControl.setValue(valorInicio + ' - ' + valorFim);

    }

    this.campoControl.updateValueAndValidity();
  }

}
