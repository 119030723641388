import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirmacao',
  templateUrl: './dialog-confirmacao.component.html',
  styleUrls: ['./dialog-confirmacao.component.css']
})
export class DialogConfirmacaoComponent {
  acao: string;
  observacao: string = '';
  observacaoError: boolean = false; // Propriedade para rastrear a validade
  observacaoErrorMessage: string = 'Este campo é obrigatório'; // Mensagem de erro

  constructor(public dialogRef: MatDialogRef<DialogConfirmacaoComponent>) {}

  fecharDialogComObservacao() {
    if (this.acao === 'cancelar' && this.observacao.trim() === '') {
      // Se a ação for "cancelar" e a observação estiver vazia, não fecha o diálogo
      this.observacaoError = true;
    } else {
      // Caso contrário, fecha o diálogo com a observação preenchida
      this.observacaoError = false;
      this.dialogRef.close(this.observacao);
    }
  }
}
