import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { RequerimentoService } from 'src/app/core/services/requerimento.service';
import { Requerimento } from 'src/app/core/models/domain/requerimento-class.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { query } from '@angular/animations';
import { PaginacaoDTO } from 'src/app/core/models/domain/paginacao-dto.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmacaoComponent } from 'src/app/core/components/gestao/datatable/dialogs/dialog-confirmacao/dialog-confirmacao.component'
import { DialogValidacaoComponent } from 'src/app/core/components/gestao/datatable/dialogs/dialog-validacao/dialog-validacao/dialog-validacao.component';
import { TipoRequerimentoService } from 'src/app/core/services/tipo.requerimento.service';
import { TipoRequerimento } from 'src/app/core/models/domain/TipoRequerimento';
import { Observable, first, map, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserResponse } from 'src/app/core/models/authentication/responses/user-response';
import { User } from 'src/app/core/models/user';
import { UserService } from 'src/app/core/services/user.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AuthenticationHelper } from 'src/app/core/helpers/authentication.helper';
import { FileService } from 'src/app/core/services/file.service';
import { FilesRequerimento } from 'src/app/core/models/domain/FilesRequerimento';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { StatusService } from 'src/app/core/services/status.service';
import { DialogEdicaoComponent } from 'src/app/core/components/gestao/datatable/dialogs/dialog-edicao/dialog-edicao.component';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { RecaptchaErrorParameters } from 'ng-recaptcha/lib/recaptcha.component';
import { AcessoExternoService } from 'src/app/core/services/acesso-externo.service';
@Component({
  selector: 'app-index',
  templateUrl: './consulta-externa.component.html',
  styleUrls: ['./consulta-externa.component.css']
})
export class ConsultaExterna implements OnInit {


  //filepdf = 'http://152.92.220.63:8080/files/segunda_via (1).pdf';

  //requerimentos: Requerimento[] = [];
  carregando: boolean;
  files: FilesRequerimento[];
  requerimentos: any[];
  paginacaoResponse: any;
  paginas: number[] = [];
  tipoRequerimentoInput: any;
  tipoRequerimento: TipoRequerimento;
  options: TipoRequerimento[] = [];
  filteredOptions: Observable<TipoRequerimento[]>;
  requerimento: any;
  numeroSEI: string;

  filespdf: FilesRequerimento[] = [];
  imagens: FilesRequerimento[] = [];

  myControl = new FormControl('');

  selectedOption: any;

  loading = false;
  userResponse: UserResponse;
  userFromApi?: User;

  isAdmin = false;

  paginaAtual: number = 1;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  formularioConsulta = new FormGroup({
    emailRequerente: new FormControl('', [Validators.required]),
    token: new FormControl('', [Validators.required]),
    numeroProtocolo: new FormControl('', [Validators.required]),
    quantidade: new FormControl(''),
    pagina: new FormControl('')
  });
  displayValue: string;

  statusOptions : any;
  isPadrao: boolean;
  mostrarErroNoFormulario: boolean;


  constructor(
    private formBuilder: FormBuilder,
    private acessoExternoService: AcessoExternoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private tipoRequerimentoService: TipoRequerimentoService,
    private statusService: StatusService,
    private fileService: FileService,
    private userService: UserService,
    private authenticationHelper: AuthenticationHelper,
    private authenticationService: AuthenticationService

  ) {

    let logado = authenticationHelper.logado();

    this.isAdmin = logado ? authenticationHelper.isAdmin() : false;
    this.isPadrao = logado ? authenticationHelper.isPadrao() : false;
    
    this.userResponse = <UserResponse>this.authenticationService.userValue;

    /*  this.formularioConsulta = this.formBuilder.group({
       matricula: [''],
       numeroProtocolo: [''],
       quantidade: ['']
     }); */
  }

  get form(): any {
    return this.formularioConsulta.controls;
  }

  ngOnInit(): void {
 

  }

  criarPaginator(){
    const customPaginatorIntl = new MatPaginatorIntl();
    customPaginatorIntl.itemsPerPageLabel = 'Requisitos por página:';
    customPaginatorIntl.firstPageLabel = 'Primeira página';
    customPaginatorIntl.nextPageLabel = 'Página seguinte';
    customPaginatorIntl.previousPageLabel = 'Página anterior';
    customPaginatorIntl.lastPageLabel = 'Última página';
    customPaginatorIntl.getRangeLabel = function (page: number, pageSize: number, length: number) {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `Exibindo ${start} a ${end > length ? length : end} de ${length} itens`;
    };
    this.paginator._intl = customPaginatorIntl;
  }


  listarRequerimentos() {

    if(!this.formularioConsulta.invalid){
      this.mostrarErroNoFormulario = false;
          this.carregando = true;
          const request = {
              emailRequerente: this.formularioConsulta.controls.emailRequerente.value || '',
              token: this.formularioConsulta.controls.token.value || '',
              numeroProtocolo: this.formularioConsulta.controls.numeroProtocolo.value || '',
            quantidade: this.formularioConsulta.value.quantidade == null || this.formularioConsulta.value.quantidade == '' ? '10' : this.formularioConsulta.value.quantidade,
            pagina: this.formularioConsulta.value.pagina == null || this.formularioConsulta.value.pagina == '' ? '1' : this.formularioConsulta.value.pagina
          }
          /* this.requerimentoService.findAll().subscribe(data => {
            this.requerimentos = data;
          }); */
          console.log(request);


        this.acessoExternoService.find(request).subscribe(data => {

          this.requerimentos = data;

          // Iterar sobre todos os requerimentos
          for (let i = 0; i < this.requerimentos.length; i++) {
              // Alterar a data de abertura de cada requerimento
              this.requerimentos[i].idRequerimento.dataAbertura = this.requerimentos[i].idRequerimento.dataAbertura.slice(0, 19);
          }
          
          

          }).add(() => {
            this.carregando = false;
          });

    }else{
      this.mostrarErroNoFormulario = true;
    }
  }


  consultar() {
    // TODO: Use EventEmitter with form value
    //console.warn(this.formularioConsulta.value);
    //console.warn(this.formularioConsulta.value);
    this.listarRequerimentos();
   // this.atualizarRotaESetarValores();
  }


  limpar() {
    this.formularioConsulta.reset();
  }
  
}
