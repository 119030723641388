import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonFunctionalityComponent } from 'src/app/core/components/common-functionality/common-functionality.component';
import { ServidorResponseDTO } from 'src/app/core/models/domain/ServidorResponseDTO';
import { criarMetaDadosPasso } from 'src/app/core/modules/custom-form/utils/meta-dados-util';
import { Step } from './step';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent extends CommonFunctionalityComponent implements OnInit {

  // Variáveis de modelo
  @Input()
  step: Step;

  @Input()
  dadosServidor: ServidorResponseDTO;

  @Input()
  primeiro: boolean;

  @Input()
  ultimo: boolean;

  @Output() onCreationStepFormGroup = new EventEmitter<any>();

  @Output() onConclusao = new EventEmitter<any>();

  // Formulário do passo corrente do componente
  stepFormGroup = this._formBuilder.group({});

  constructor(private _formBuilder: FormBuilder, public override router: Router) {
    super(router);
  }

  override ngOnInit(): void {
    this.onCreationStepFormGroup.emit(this.stepFormGroup);
    this.stepFormGroup.metaDados = criarMetaDadosPasso(this.step);
  }

  enviarDadosParaConclusao() {
    this.onConclusao.emit();
  }

}
