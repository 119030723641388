import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable, map, startWith } from 'rxjs';
import { RequerimentoService } from 'src/app/core/services/requerimento.service';

import { Requerimento } from 'src/app/core/models/domain/requerimento-class.model';
import { DataTableComponent } from '../datatable/datatable.component';
import { MatTable } from '@angular/material/table';


@Component({
  selector: 'app-requerimento-filter',
  template: `
    <app-requerimento-data-table>
    </app-requerimento-data-table>
  `,
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})

export class FilterComponent {
  filterForm: FormGroup;
  requerimento: any;
  matricula: any;
  status: any;
  tipo: any;
  protocolo: any;
  numeroSEI: any;
  myControl = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  constructor(private formBuilder: FormBuilder, private requerimentoService: RequerimentoService, private dataTable: DataTableComponent) {
    this.filterForm = this.formBuilder.group({
      requerimento: [''],
      matricula: [''],
      status: [''],
      tipo: [''],
      numeroProtocolo: [''],
      numeroSei: [''],
    });
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  searchObjects(searchModel: any) {
    const body = { matriculaRequisitado: searchModel.value.matricula };
    this.dataTable.buscarRequerimento(body);
  }

}
