import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class StatusService {
  private requerimentoUrl: string;
  private matricula: string;

  statusOptions = [
    { value: '', label: 'Todos' },
    { value: '1', label: 'Aberto' },
    { value: '2', label: 'Validado' },
    { value: '3', label: 'Processo criado' },
    { value: '4', label: 'Finalizado' },
    { value: '5', label: 'Cancelado' },
    
  ];

  constructor() {
    this.requerimentoUrl = environment.apiUrlRequerimento + '/requerimento/';
    //this.requerimentoUrl = 'http://localhost:8085/api/requerimento/';
  }

  public findAll(): Observable<any[]> {
    //return this.http.get<any[]>(this.requerimentoUrl + 'all');
    return of(this.statusOptions); // Use o operador 'of' para criar um Observable com this.statusOptions
  }
}
