<!-- <header *ngIf="userResponse" class="shadow-sm"></header> -->
<!-- <div class="container--">
  <router-outlet></router-outlet>
</div> -->
<main> <router-outlet></router-outlet> </main>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#3f51b5"
  type="ball-atom"
>
  <p style="font-size: 20px; color: white">Autenticando Usuário...</p>
</ngx-spinner>
