import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationHelper } from 'src/app/core/helpers/authentication.helper';
import { PapelResponse } from 'src/app/core/models/authentication/responses/papel-response';
import { UserResponse } from 'src/app/core/models/authentication/responses/user-response';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CommonFunctionalityComponent } from '../../common-functionality/common-functionality.component';
import { StatusEnum } from './status-enum';

@Component({
  selector: 'app-abertura-requerimento-ref',
  templateUrl: './abertura.component.html',
  styleUrls: ['./abertura.component.css']
})
export class AberturaRequerimentoRefComponent extends CommonFunctionalityComponent implements OnInit {

  // Usuário e autenticação
  logado: Boolean = false;
  userResponse?: UserResponse | null;
  papeis: PapelResponse[] = [];

  // Estágio:
  passoAtual: StatusEnum;

  // Status e inicialização
  public statusEnum = StatusEnum;
  statusCorrente: StatusEnum = StatusEnum.INICIO;

  constructor(private auth: AuthenticationService, private authHelper: AuthenticationHelper, public override router: Router) {

    super(router);

    // Verificando se o usuário está logado e pegando os papeis
    this.auth.userResponse.subscribe(x => this.userResponse = x);
    this.logado = this.authHelper.logado();
    this.papeis = this.authHelper.getPapeis();

    this.passoAtual = this.statusEnum.INICIO;

  }

  override ngOnInit(): void { }

}
