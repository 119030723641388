<!-- criar outros layouts e remover essa condicional -->
<header class="shadow-sm">
  <nav class="nav-sisre navbar navbar-expand-lg navbar-dark bg-primary">
    <div class="container d-flex">
      <div class="p-2 p-xs-1 flex-grow-1">
        <a class="navbar-brand" [routerLink]="'/'">
          <img class="img-responsive" height="80" src="./assets/imagens/logo_sisre_branco.png" />
          <!--<img class="img-responsive img-thumbnail rounded-5 " width="40" src="./assets/imagens/sisre-logo.jpg">
             <span class="p-2 fs-2 fw-bold text-sisre">SISRE</span>-->
        </a>
      </div>
      <div class="p-2 p-xs-1 pe-0 collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" aria-current="page"
              [routerLink]="'/'">Início</a>
          </li>
          <li class="nav-item" *ngIf="userResponse">
            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" aria-current="page"
              [routerLink]="'/dashboard'">Status</a>
          </li>
          <!--<li class="nav-item" *ngIf="userResponse">
              <a class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/requerimentos'">Requerimentos</a>
            </li>-->
          <!--<li class="nav-item" *ngIf="userResponse">
              <a class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/requerimentos-antigo'">Requerimentos antigo</a>
            </li>-->
          <li class="nav-item" *ngIf="!userResponse">
            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              [routerLink]="'/login'">Login</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              [routerLink]="'/ajuda'">Ajuda</a>
          </li>
          <!-- <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Drop
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
               
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link disabled">Disabled</a>
            </li> -->
        </ul>
      </div>
      <div class="p-2 p-xs-1 ps-0">

        <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
          <li class="nav-item p-1">

            <!-- <a class="nav-link navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </a> -->
            <a class="nav-link navbar-toggler" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
              aria-controls="offcanvasExample">
              <span class="navbar-toggler-icon"></span>
            </a>
          </li>
          <li *ngIf="userResponse">
            <div class="dropdown notificacoes-icon" hidden>
              <a class="nav-link text-white dropdown-toggle ps-0" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <!-- <mat-icon matBadge="15" matBadgeColor="warn">home</mat-icon> -->
                <!-- <div matBadge="4" matBadgeOverlap="false" class="demo-section">a</div>   -->
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pl-2 bi bi-person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                </svg> -->
                <span matBadge="4" matBadgeOverlap="false" class="demo-section">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                    class="bi bi-bell-fill" viewBox="0 0 16 16">
                    <path
                      d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                  </svg>
                </span>
              </a>

              <div class="dropdown-menu dropdown-menu-end notificacoes p-0 shadow-lg">

                <div class="list-group">
                  <a href="#" class="list-group-item list-group-item-action" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">Sub título 1</h5>
                      <small>1 dias atrás</small>
                    </div>
                    <p class="mb-1">Título principal.</p>
                    <small>Resumo da mensagem.</small>
                  </a>
                  <a href="#" class="list-group-item list-group-item-action" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">Sub título 1</h5>
                      <small>2 dias atrás</small>
                    </div>
                    <p class="mb-1">Título principal.</p>
                    <small>Resumo da mensagem.</small>
                  </a>
                  <a href="#" class="list-group-item list-group-item-action" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">Sub título 1</h5>
                      <small>3 dias atrás</small>
                    </div>
                    <p class="mb-1">Título principal.</p>
                    <small>Resumo da mensagem.</small>
                  </a>
                  <a href="#" class="list-group-item list-group-item-action" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">Sub título 1</h5>
                      <small>3 dias atrás</small>
                    </div>
                    <p class="mb-1">Título principal.</p>
                    <small>Resumo da mensagem.</small>
                  </a>
                </div>

              </div>
            </div>
          </li>
          <li *ngIf="userResponse">
            <div class="dropdown">
              <a class="nav-link text-white dropdown-toggle ps-0" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                  class="pl-2 bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                </svg>
                <span class="p-2- ps-2- small"><!-- {{userResponse.cpf}} --></span>
              </a>

              <ul class="dropdown-menu shadow-lg dropdown-menu-end">
                <li class="mb-1 p-3"><span class="small fw-bold">{{userResponse.nomeCompleto}}</span></li>
                <li class="px-3"><span class="small fw-bold pe-3">CPF:</span><span
                    class="small">{{userResponse.cpf}}</span></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li class="p-1"><a class="dropdown-item rounded-1" [routerLink]="'/logout'" (click)="logout()"> Logout
                  </a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <nav class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header p-4 bg-primary">
      <a class="navbar-brand" [routerLink]="'/'">
        <img class="img-responsive" height="80" src="./assets/imagens/logo_sisre_branco.png" />
      </a>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="'/'">Início</a>
        </li>
        <li class="nav-item" *ngIf="!userResponse">
          <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            [routerLink]="'/login'">Login</a>
        </li>

        <li class="nav-item dropdown" *ngIf="userResponse">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Requerimentos
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="['/abertura', { outlets: { 'abertura': ['inicio'] } } ]">Abertura de requerimentos</a>
            </li>
            <li><a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="'/requerimentos'">Consulta de requerimentos</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown" *ngIf="userResponse">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Atendimento
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="'/requerimentos'">Gestão de requerimentos</a></li>
            <li><a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="['/reenvio-token']">Reenvio de token para usuário externo</a></li>
          </ul>
        </li>
        <li class="nav-item" *ngIf="userResponse">
          <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="'/ajuda'">Ajuda</a>
        </li>
      </ul>
    </div>
  </nav>



  <div class="px-3 py-2 border-bottom mb-3 bg-white">
    <nav class="container navbar navbar-expand-lg col-12 p-0">
      <div class="container d-flex">
        <div class="p-0 flex-grow-1 col-lg-auto col-12">
          <ul class="nav-sisre-submenu-duplo collapse navbar-collapse navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" href="#">Início</a>
            </li>
            <li class="nav-item dropdown" *ngIf="userResponse">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Meus requerimentos
              </a>
              <ul class="dropdown-menu">
                <!--<li><a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"  aria-current="page" [routerLink]="'/requerimento/abertura'">Abertura de requerimentos</a></li>-->
                <li><a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                    aria-current="page" [routerLink]="['/abertura', { outlets: { 'abertura': ['inicio'] } } ]">Abertura
                    de requerimentos</a></li>
                <li><a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                    aria-current="page" [routerLink]="'/requerimentos'" *ngIf="isPadrao">Consulta de requerimentos</a>
                </li>

                <!-- <li><hr class="dropdown-divider"></li> -->
              </ul>
            </li>
            <!--<li class="nav-item dropdown" *ngIf="userResponse">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Usuário Interno
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </li>-->
            <li class="nav-item dropdown" *ngIf="!userResponse && !isPadrao">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                *ngIf="userResponse">
                Requerimentos
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                    aria-current="page" [routerLink]="'/requerimento/abertura'">Abertura de requerimentos</a></li>
                <li><a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                    aria-current="page" [routerLink]="'/requerimentos'">Consulta de requerimentos</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown" *ngIf="userResponse  && !isPadrao">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Atendimento
              </a>
              <ul class="dropdown-menu">
                <!--<li><a class="dropdown-item" href="#">Lista de solicitações requecibas</a></li>-->
                <li><a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                    aria-current="page" [routerLink]="'/requerimentos'">Gestão de requerimentos</a></li>
                <li><a class="dropdown-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                    aria-current="page" [routerLink]="['/reenvio-token']">Reenvio de token para usuário externo</a></li>
              </ul>
            </li>
            <!--<li class="nav-item">
               <a class="nav-link disabled">Desabilitado</a>
             </li>-->
          </ul>
        </div>
        <!--<div class="pe-2 pe-0 col-lg-auto col-12">
            <form class="col-12-- col-lg-auto mb-2 mb-lg-0 me-lg-auto" role="search">
              <input type="search" class="form-control" placeholder="Buscar..." aria-label="Search">
            </form>
          </div>-->
        <!--<div class="p-2 ps-0 text-end col-lg-auto col-12">-->
        <!-- <button type="button" class="btn btn-light text-dark me-2">Login</button> -->
        <!-- <button type="button" class="btn btn-success">Novo Requerimento</button> -->
        <!--<button mat-flat-button color="primary">Novo Requerimento</button>
          </div>-->
      </div>
    </nav>
    <!-- <div class="container d-flex flex-wrap justify-content-center">
        <form class="col-12 col-lg-auto mb-2 mb-lg-0 me-lg-auto" role="search">
          <input type="search" class="form-control" placeholder="Buscar..." aria-label="Search">
        </form>
        <div class="text-end">    
          <button type="button" class="btn btn-light text-dark me-2">Login</button>
          <button type="button" class="btn btn-success">Novo Requerimento</button>
        </div>
      </div> -->
  </div>
</header>
<div class="container container-min-height">
  <router-outlet></router-outlet>
</div>
<div class="container-fluid bg-white">
  <footer class="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top mb-0">
    <div class="col-md-4 d-flex align-items-center">
      <span class="mb-3 mb-md-0 text-muted"><a href="https://www.ouvidoria.uerj.br/"
          style="color: #000;">Ouvidoria-Geral da UERJ</a> | <a
          href="https://www.ouvidoria.uerj.br/sic-servico-de-informacao-ao-cidadao/" style="color: #000;">Solicitação de
          acesso à informação</a> </span>
    </div>
    <div class="col-md-4 d-flex align-items-center">
      <!--  <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
  <div class="container-fluid bg-white">
    <footer class="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top mb-0">	
	   <div class="col-md-4 d-flex align-items-center">
          <span class="mb-3 mb-md-0 text-muted"><a href="https://www.ouvidoria.uerj.br/" style="color: #000;" target="_blank">Ouvidoria-Geral da UERJ</a> | <a href="https://www.ouvidoria.uerj.br/sic-servico-de-inforamcao-ao-cidadao/" style="color: #000;" target="_blank">Solicitação de acesso à informação</a> </span>
       </div>		
      <div class="col-md-4 d-flex align-items-center">
       <!--  <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
          <svg class="bi" width="30" height="24"><use xlink:href="#bootstrap"></use></svg>
        </a> -->
      <a class="navbar-brand d-flex align-items-center me-2" href="https://www.sgp.uerj.br/site/" target="_blank">
        <img class="img-responsive img-thumbnail rounded-5 " width="40" src="./assets/imagens/sgp-logo.png">
        <!-- <span class="p-2 fs-2 fw-bold text-sisre">SISRE</span> -->
      </a>
      <span class="mb-3 mb-md-0 text-muted">© 2023 UERJ, Inc</span>
    </div>

    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
      <!--<li class="ms-3"><a class="text-primary" href="#"><i class="bi bi-" style="font-size: 24px;"></i></a></li>-->
      <li class="ms-3"><a class="text-primary" href="https://api.whatsapp.com/send?phone=552123340416"
          target="_blank"><i class="bi bi-whatsapp" style="font-size: 24px;"></i></a></li>
      <li class="ms-3"><a class="text-primary" href="https://www.instagram.com/sgp.uerj/" target="_blank"><i
            class="bi bi-instagram" style="font-size: 24px;"></i></a></li>
      <li class="ms-3"><a class="text-primary" href="https://www.facebook.com/sgp.uerj" target="_blank"><i
            class="bi bi-facebook" style="font-size: 24px;"></i></a></li>
    </ul>
  </footer>
</div>