export class AuthenticationRequest {

    identificador: string;
    senha?: string;
    token?: string;

    constructor(identificador: string, senha: string){
        this.identificador = identificador;
        this.senha = senha;
    }

}
