import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { Role } from '../../models/role';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationRequest } from '../../models/authentication/requests/authentication-request';
import { PapelResponse } from '../../models/authentication/responses/papel-response';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  loading = false;
  submitted = false;
  mensagemErro = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.userValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // para pegar os controls do form
  get f() { return this.loginForm.controls; }

  onSubmit() {

    this.ngxSpinnerService.show();

    this.submitted = true;

    // interrompe se o formulário estiver inválido
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    const username = this.f.username.value;
    const password = this.f.password.value;

    const authenticationRequest = new AuthenticationRequest(username, password);

    this.authenticationService.login(authenticationRequest)
      .subscribe({
        
        next: (response) => {

          console.log(response.userResponse.papeis);

          const otherCategories = response.userResponse.papeis.some((item: { categoria: string; }) => item.categoria !== 'ALUNO' && item.categoria !== null);


          if(otherCategories){


          let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
          
          /*if (this.authenticationService.userValue.isAdmin) {
            returnUrl = '/dashboard';
          }*/

          this.router.navigateByUrl(returnUrl);
          }
          else{
            this.authenticationService.logout();
            this.mensagemErro = 'O acesso ao sistema é permitido somente aos docentes, servidores e contratados.';
          }


        },
        error: (e) => {

          switch (e.status) {
            case 403:
            this.mensagemErro = e.error.errors[0];
            break;
            default:
            this.mensagemErro = 'Erro ao autenticar o usuário.';
            break;
            console.log(e);
          }

        }

      }).add(() => {

        this.loading = false;
        this.ngxSpinnerService.hide();
        
      });
  }

}