import { Component } from '@angular/core';
import { UserResponse } from '../../models/authentication/responses/user-response';
import { AuthenticationService } from '../../services/authentication.service';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent {

  userResponse: UserResponse;

 
  constructor(
    private authenticationService: AuthenticationService,

  ){
    this.userResponse = <UserResponse>this.authenticationService.userValue;
  }

  
}
