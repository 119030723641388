import { Component, ElementRef, Inject, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AberturaRequerimentoComponent } from '../../abertura-requerimento.component';
import { RequerimentoFile } from 'src/app/core/models/domain/requerimento-file';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-files-requerimento',
  templateUrl: './files-requerimento.component.html',
  styleUrls: ['./files-requerimento.component.css']
})
export class FilesRequerimentoComponent {

  @Input()
  public campo: any;

  @Input()
  public parentFormGroup: FormGroup;

  @Input()
  public formGroup: any;

  @Input()
  public filesArray: RequerimentoFile[];

  public viewContainerRef : ViewContainerRef;

  @Input()
  removivel: boolean;

  public fileName: String | null;

  constructor(@Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, private el: ElementRef){
    this.viewContainerRef = viewContainerRef;
  }

  /*
    Método que adiciona um arquivo para upload
  */
  public addFileToUpload(event: any, fileType: string, idParent: number) {

    
    
    const file = (event.target as HTMLInputElement).files[0];

    const allowedExtensions = ['.pdf', '.jpeg', '.jpg', '.doc', '.png', '.docx'];


    if (file && !allowedExtensions.some(ext => file.name.toLowerCase().endsWith(ext))) {
      alert('Arquivos apenas podem ser: pdf, jpeg , doc e png.');
      return;

    }else{
              // Remove do array caso exista
              this.removeFromArray(this.campo);

              // Adiciona o arquivo no array
              this.filesArray.push({nome: fileType, value: file, idParent: idParent, filename: file.name});
              this.fileName = file.name;
              
              // Seta o nome do arquivo como valor no objeto que representa o campo
              this.formGroup.get("valor")?.setValue(file.name);

              // Seta o nome do arquivo
              let element = this.el.nativeElement.querySelector('#spanFile_' + this.campo.nome);
              element.textContent = this.fileName;
    }

  }

  /*
    Método responsável por remover o arquivo no FormGroup, html e no array
  */
  public removeFile(id: string){

    // Removendo o control do campo no FormGroup
    this.parentFormGroup.removeControl(this.campo.nome);

    // Removendo o campo no html
    document.getElementById(id).remove();

    // Remove do array caso exista
    this.removeFromArray(this.campo);

  }

  /* 
    Remove o campo do array
  */
  private removeFromArray(campo: any){

    // Verifica se o arquivo está no array
    let indexFile = this.filesArray.findIndex((obj) =>{
      return obj.nome == campo.nome
    });

    // Se estiver retira ele do array
    if(indexFile != -1){
      this.filesArray.splice(indexFile, 1);
    }

  }

}
