import { AfterViewInit, Component, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import * as moment from 'moment';
import { Grupo } from 'src/app/core/models/domain/Grupo';
import { criarMetaDadosGrupo } from 'src/app/core/modules/custom-form/utils/meta-dados-util';
import { formatCNPJ, formatCPF, isFile, isInputTipo } from '../campo/util/campo-util';
import { GrupoComponent } from '../grupo.component';
import { getFormGroup } from '../util/grupo-util';
import { MultiValoradoStatus } from './multi-valorado-status';

export interface ColumnDefinition {
  nome: string;
  label: string;
}

@Component({
  selector: 'app-grupo-multi-valorado',
  templateUrl: './multi-valorado.component.html',
  styleUrls: ['./multi-valorado.component.css']
})
export class GrupoMultiValoradoComponent implements OnInit, AfterViewInit {

  // Variáveis do DataTable
  displayedColumns: string[] = [];
  columns: ColumnDefinition[] = [];
  dataSource: any[] = [];
  data: { [key: string]: string } = {};

  readonly ACOES = { nome: 'acoes', label: 'Ações' };

  @ViewChild(MatTable) table: MatTable<any>;

  @Input()
  grupo: Grupo;

  @Input()
  stepFormGroup: FormGroup;

  @Input()
  grupoChamador: Grupo;

  // FormArray responsável por armazenar os controls do Grupo Multivalorado
  grupoArray: FormArray;

  // Form atual está sendo manipulado
  formGroupAtual: FormGroup;

  // Ref para criação do componente dinâmicamente
  refAtual: ComponentRef<GrupoComponent>;

  @ViewChild("viewContainerRef", { read: ViewContainerRef }) vcr!: ViewContainerRef;

  // Variáveis úteis
  submited: boolean = false;

  readonly dateFormat = 'DD/MM/YYYY';

  statusComponent = MultiValoradoStatus.inicio;

  @Input()
  chamado: boolean = false;

  excluidoRegistro: boolean = false;

  constructor(private formBuilder: FormBuilder) { }

  ngAfterViewInit(): void {
    this.adicionarGrupo();
  }

  ngOnInit(): void {

    // Inicialização das colunas
    this.initColumns();

    // Inicialização do array do Grupo
    this.initGrupoMultivalorado();

  }

  // Método responsável por inicializar as colunas e limintar o tamanho para 4
  initColumns() {

    this.grupo.campos.forEach(campo => {

      if (!isFile(campo) && this.columns.length < 4) {
        let columnDefinition = { nome: campo.nome, label: campo.label };
        this.columns.push(columnDefinition);
      }

    });

    this.displayedColumns = this.columns.map(col => col.nome);
    this.displayedColumns.push(this.ACOES.nome);

  }

  // Inicializa e adiciona o grupo multivalorado no formulário do passo
  initGrupoMultivalorado() {

    this.grupoArray = this.formBuilder.array([]);
    this.grupoArray.metaDados = criarMetaDadosGrupo(this.grupo);
    this.grupoArray.addValidators(this.grupoMultivaloradoValidator());

    let formGroup = (!this.chamado) ? this.stepFormGroup : getFormGroup(this.stepFormGroup, this.grupoChamador);
    formGroup.addControl(this.grupo.label, this.grupoArray);

  }

  // Adiciona os dados inseridos pelo usuário no formArray e no DataTable
  adicionar() {

    this.excluidoRegistro = false;

    if (this.formGroupAtual.valid) {

      for (const control in this.formGroupAtual.controls) {

        const formControl = this.formGroupAtual.controls[control];

        const label = formControl.value?.label;
        let value = formControl.value as string;

        if (isInputTipo(formControl.metaDados?.tipoComponente)) {

          const labelMetadados = formControl.metaDados?.label.toLowerCase();

          if (labelMetadados.includes('cpf')) {
            value = formatCPF(value);
          } else if (labelMetadados.includes('cnpj')) {
            value = formatCNPJ(value);
          }

        }

        // Tratativa para campos do tipo Calendar
        if (moment.isMoment(value)) {
          value = moment(value).format(this.dateFormat);
        }

        this.data[control] = (label) ? label : value;

      }

      // Adição no DataTable
      this.dataSource.push(this.data);
      this.table?.renderRows();

      // Deleção do componente na UI
      this.refAtual.destroy();

      // Atribuição das variáveis para o estado devido
      this.data = {};
      this.statusComponent = MultiValoradoStatus.incluido;

    }

    this.formGroupAtual.updateValueAndValidity();

  }

  // Método responsável por resetar o formulário
  resetarForm() {

    this.formGroupAtual.reset();
    this.excluidoRegistro = false;

    // Resetando os campos multivalorados e campos do tipo file
    Object.keys(this.formGroupAtual.controls).forEach(key => {
      let control = this.formGroupAtual.controls[key];
      control.reset();
      control.updateValueAndValidity();
    });

  }

  // Adiciona o novo grupo na UI, criando o componente dinamicamente e inserindo no array do Grupo
  adicionarGrupo() {

    // Criação do novo componente
    this.refAtual = this.vcr.createComponent(GrupoComponent) as ComponentRef<GrupoComponent>;

    // Atribuição das variáveis do novo componente
    this.refAtual.instance.grupo = this.grupo;
    this.refAtual.instance.chamado = this.chamado;
    this.refAtual.instance.grupoChamador = this.grupoChamador;
    this.refAtual.instance.stepFormGroup = this.stepFormGroup;
    this.refAtual.instance.hostView = this.refAtual.hostView;
    this.refAtual.instance.vcr = this.vcr;

    this.formGroupAtual = new FormGroup([]);

    this.refAtual.instance.grupoControl = this.formGroupAtual;

    // Adição no array do Grupo
    this.grupoArray.push(this.formGroupAtual);

    this.statusComponent = MultiValoradoStatus.preenchimento;

  }

  // Remove o registro clicado pelo usuário
  removerRegistro(row: any) {
    const index = this.dataSource.indexOf(row);
    this.dataSource.splice(index, 1);
    this.grupoArray.removeAt(index);
    this.table.renderRows();

    if (this.dataSource.length == 0 && this.grupoArray.length == 0) {
      this.adicionarGrupo();
    } else if (this.grupoArray.length > 1) {
      this.excluidoRegistro = true;
    }

  }

  // Método utilitário para verificação do estado do componente
  checkStatus(status: string): boolean {
    return this.statusComponent.toString() == status;
  }

  // Cancelar a ação de inserção do usuário
  cancelar() {
    this.excluidoRegistro = false;
    this.refAtual.destroy();
    this.statusComponent = MultiValoradoStatus.cancelado;
    this.grupoArray.removeAt(this.grupoArray.length - 1);
  }


  // Valida a opção digitada, verificando se a lista contém o que foi digitado pelo usuário
  public grupoMultivaloradoValidator(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {

      let minQtd = this.dataSource.length > 0;

      return minQtd ? null : { minQtdGrupo: true };

    };

  }

}
