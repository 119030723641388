import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TipoRequerimento } from '../models/domain/TipoRequerimento';

@Injectable()
export class TipoRequerimentoService {
  private formularioUrl: string;
  private matricula: string;
  public headers: any;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  }

  public findAll(): Observable<TipoRequerimento[]> {
    return this.http.get<TipoRequerimento[]>(environment.apiUrlRequerimento + '/requerimento/tipo/all');
  }

  public findAllPorPapeisUsuario(papeis: any, logado: boolean): Observable<TipoRequerimento[]> {
    return this.http.get<TipoRequerimento[]>(environment.apiUrlFormulario + '/formulario/tipoRequerimento' + ((logado) ? '?idPapeis=' + papeis?.toString() : ''));
  }

  public findAllPorTipoOperacao(id: any): Observable<TipoRequerimento[]> {
    return this.http.post<TipoRequerimento[]>(environment.apiUrlRequerimento + '/requerimento/tipo/all/tipoOperacao', JSON.stringify(id), { headers: this.headers });
  }


  public findGruposTipoRequerimento(id: any): Observable<[]> {
    return this.http.get<[]>(environment.apiUrlFormulario + '/formulario/tipoRequerimento/' + id.id);
  }

  public findGruposTipoRequerimentoOperacao(id: any): Observable<[]> {
    return this.http.get<[]>(environment.apiUrlFormulario + '/formulario/tipoRequerimento/' + id.id + '/' + id.idOp);
  }

  public findStepperTipoRequrimento(id: any): Observable<[]> {
    return this.http.get<[]>(environment.apiUrlFormulario + '/formulario/tipoRequerimento/stepper/' + id.id);
  }

  public getSteps(params: any): Observable<[]> {
    return this.http.get<[]>(environment.apiUrlFormulario + '/formulario/tipoRequerimento/steps/' + params.id + '/' + params.idOperacao);
  }

}
