import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, map, startWith } from 'rxjs';
import { AppInjector } from 'src/app/app.module';
import { AuthenticationHelper } from 'src/app/core/helpers/authentication.helper';
import { PapelResponse } from 'src/app/core/models/authentication/responses/papel-response';
import { ServidorResponseDTO } from 'src/app/core/models/domain/ServidorResponseDTO';
import { TipoOperacao } from 'src/app/core/models/domain/TipoOperacao';
import { TipoRequerimento } from 'src/app/core/models/domain/TipoRequerimento';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { ServidorService } from 'src/app/core/services/servidor.service';
import { TipoRequerimentoService } from 'src/app/core/services/tipo.requerimento.service';
import { CommonFunctionalityComponent } from '../../../common-functionality/common-functionality.component';

@Component({
  selector: 'app-inicio-abertura-requerimento',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioAberturaRequerimentoComponent extends CommonFunctionalityComponent implements OnInit {

  // Usuário
  papeis: PapelResponse[] = [];

  // Formulários
  requerimentoFormGroup: FormGroup;

  // FormControls em memória
  matriculaFormControl: FormControl;
  tipoRequerimentoFormControl: FormControl;
  subGrupoRequerimentoFormControl: FormControl;
  tipoOperacaoFormControl: FormControl;

  // Mensagens
  @Input() input: string;
  errorMessage: string;

  // Listas de Opções
  tiposRequerimento: TipoRequerimento[] = [];
  subGruposTipoRequerimento: TipoRequerimento[];
  tiposOperacao: TipoOperacao[];

  // Variáveis Auxiliares
  temSubGrupos: boolean = true;
  papelSelecionado: PapelResponse;
  logado: boolean = false;

  requerimentosFiltrados: Observable<TipoRequerimento[]>;

  readonly nameSpinner: string = 'inicioSpinner';

  readonly nameFormularioSpinner: string = 'formularioSpinner';

  textoFormularioSpinner: string = '';

  tipoRequerimentoService: TipoRequerimentoService = AppInjector.get(TipoRequerimentoService);

  servidorService: ServidorService = AppInjector.get(ServidorService);

  dadosServidor: any;

  constructor(private formBuilder: FormBuilder, private helper: AuthenticationHelper, private errorHandlerService: ErrorHandlerService,
    public override router: Router, private ngxSpinnerService: NgxSpinnerService) {

    super(router);

    // Carrega papéis do usuário 
    this.logado = (helper.logado());
    this.papeis = helper.getPapeis()?.filter((item: { categoria: string }) => item.categoria !== 'ALUNO');

    // Inicializa os controls do Formulário
    this.matriculaFormControl = new FormControl('', (this.logado) ? Validators.required : []);
    this.tipoRequerimentoFormControl = new FormControl({ value: '', disabled: this.logado }, Validators.required);
    this.subGrupoRequerimentoFormControl = new FormControl({ value: '', disabled: true }, Validators.required);
    this.tipoOperacaoFormControl = new FormControl({ value: '', disabled: true }, Validators.required);

    this.tipoRequerimentoFormControl.addValidators(this.typeValidator());

    // Atribui os controles ao Formulário
    this.requerimentoFormGroup = this.formBuilder.group({
      matricula: this.matriculaFormControl,
      tipoRequerimento: this.tipoRequerimentoFormControl,
      subGrupoRequerimento: this.subGrupoRequerimentoFormControl,
      tipoOperacao: this.tipoOperacaoFormControl
    });

  }

  override ngOnInit(): void {
    if (!this.logado) {
      this.listarTiposRequerimento();
    }

    this.tipoRequerimentoFormControl.addValidators(this.typeValidator());
    this.ngxSpinnerService.hide(this.nameFormularioSpinner);
  }

  /* 
  
    Método responsável por listar todos os Tipos de Requerimento de 
    acordo com o papel do usuário ou acesso externo (papel com ID 1)
  
  */
  listarTiposRequerimento() {

    this.errorMessage = '';

    this.ngxSpinnerService.show(this.nameSpinner);
    let matricula = this.matriculaFormControl.value ? this.matriculaFormControl.value as PapelResponse : null;
    this.tipoRequerimentoService.findAllPorPapeisUsuario(matricula?.id, this.logado).subscribe({


      next: (v) => {

        this.tiposRequerimento = v
        this.tipoRequerimentoFormControl.enable();

        this.requerimentosFiltrados = this.tipoRequerimentoFormControl.valueChanges.pipe(
          startWith(''),
          map(value => {
            const name = typeof value === 'string' ? value : value?.name;
            return name ? this._filter(name as string) : this.tiposRequerimento.slice();
          }),
        );

      },
      error: (e) => {

        this.errorHandlerService.handle(e);
        this.errorMessage = e.status == 0 ? 'Erro ao listar os Tipos de Requerimentos' : e.message;
        console.log(e.status == 0 ? 'Sem comunicação com a API Formulário' : e.status + e.message);
        this.ngxSpinnerService.hide(this.nameSpinner);

      },
      complete: () => {
        console.info('complete');
        this.ngxSpinnerService.hide(this.nameSpinner);
      }

    });

    this.resetarFormularioRequerimento();

  }

  /*
  
    Lista os SubGrupos do TipoRequerimento selecionado
  
  */
  listarSubGruposTipoRequerimento() {

    let tipoRequerimentoSelecionado = this.tipoRequerimentoFormControl.value as TipoRequerimento;
    this.temSubGrupos = tipoRequerimentoSelecionado?.tipoRequerimentoFilhos?.length > 0;

    if (this.temSubGrupos) {
      this.subGruposTipoRequerimento = tipoRequerimentoSelecionado.tipoRequerimentoFilhos.filter(item => item.visivel === true);
      this.subGrupoRequerimentoFormControl.enable();
    } else {
      this.subGrupoRequerimentoFormControl.disable();
      this.subGruposTipoRequerimento = null;
      this.listarTipoOperacoes(tipoRequerimentoSelecionado);
    }

    this.subGrupoRequerimentoFormControl.setValue('');
  }

  // Método para exibição no autocomplete
  displayFn(opcao: TipoRequerimento): string {
    return opcao?.nome;
  }

  // Filtragem no autocomplete
  private _filter(name: string): TipoRequerimento[] {

    const filterValue = name.toLowerCase();
    return this.tiposRequerimento.filter(tipoRequerimento => tipoRequerimento.nome.toLowerCase().includes(filterValue));

  }

  /*

    Lista os TiposOperacao do Subgrupo ou TipoRequerimento selecionado

  */
  listarTipoOperacoes(tipoRequerimento: TipoRequerimento | null) {

    let subGrupoRequerimentoSelecionado = (tipoRequerimento) ? tipoRequerimento : this.requerimentoFormGroup.controls['subGrupoRequerimento'].value as TipoRequerimento;
    this.tiposOperacao = subGrupoRequerimentoSelecionado.tipoOperacoes;

    if (this.tiposOperacao?.length == 1) {
      this.tipoOperacaoFormControl.disable();
      this.tipoOperacaoFormControl.setValue(this.tiposOperacao[0]);
    } else {
      this.tipoOperacaoFormControl.enable();
      this.tipoOperacaoFormControl.setValue('');
    }


  }

  submit() {

    const subGrupoRequerimento = this.subGrupoRequerimentoFormControl.value;
    const tipoRequerimento = this.tipoRequerimentoFormControl.value;

    this.textoFormularioSpinner = tipoRequerimento.nome + ((subGrupoRequerimento) ? ' > ' + subGrupoRequerimento.nome : '');
    this.ngxSpinnerService.show(this.nameFormularioSpinner);

    let papelReponse = this.matriculaFormControl.value as PapelResponse;

    let params = {
      'matricula': (papelReponse) ? JSON.stringify(papelReponse.matricula) : '',
      'tipoRequerimento': JSON.stringify(this.tipoRequerimentoFormControl.value as TipoRequerimento),
      'subGrupoRequerimento': JSON.stringify(this.subGrupoRequerimentoFormControl.value as TipoRequerimento),
      'tipoOperacao': JSON.stringify(this.tipoOperacaoFormControl.value as TipoOperacao),
      'dadosServidor': ''
    }

    // Inicializa os dados funcionais
    if (papelReponse.matricula) {

      this.servidorService.findByMatricula(papelReponse.matricula).subscribe({
        next: (data: ServidorResponseDTO) => {
          params['dadosServidor'] = JSON.stringify(data);
          this.router.navigate(['/abertura', { outlets: { 'abertura': ['step', JSON.stringify(params)] } }]);
        },
        error: (e: { status: number; message: string; }) => {

          this.errorHandlerService.handle(e);
          this.errorMessage = e.status == 0 ? 'Erro ao listar os dados do Servidor logado' : e.message;
          console.log(e.status == 0 ? 'Sem comunicação com a API Servidor' : e.status + e.message);

        },
        complete: () => {
          console.info('Carregado os dados do Servidor Logado.');
          this.ngxSpinnerService.hide(this.nameFormularioSpinner);
        }
      });

    } else {
      this.router.navigate(['/abertura', { outlets: { 'abertura': ['step', JSON.stringify(params)] } }]);
    }

  }
  /*
  
  Reinicia os estados e os valores dos campos

  */
  resetarFormularioRequerimento() {

    this.tipoRequerimentoFormControl.disable();
    this.subGrupoRequerimentoFormControl.disable();
    this.tipoOperacaoFormControl.disable();
    this.tipoRequerimentoFormControl.setValue('');
    this.subGrupoRequerimentoFormControl.setValue('');
    this.tipoOperacaoFormControl.setValue('');

  }

  // check if value exists in predefined options
  autoCompleteValidator(): boolean {

    let isValid: boolean;

    this.requerimentosFiltrados.subscribe(tiposRequerimento =>

      isValid = tiposRequerimento.some(tipoRequerimento =>
        tipoRequerimento.nome === this.tipoRequerimentoFormControl?.value
      )

    );

    if (!isValid) {
      this.tipoRequerimentoFormControl.setErrors({ requeried: true });
    }

    return isValid;

  }

  // Valida a opção digitada, verificando se a lista contém o que foi digitado pelo usuário
  public typeValidator(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedType = control.value as TipoRequerimento;

      let isValid = this.tiposRequerimento.some(tipoRequerimento =>
        tipoRequerimento.nome == selectedType.nome
      )

      console.log(control);
      return isValid ? null : { required: true };
    };

  }

}


