import { Component, ComponentRef, Input, OnInit, ViewContainerRef, ViewRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonFunctionalityComponent } from 'src/app/core/components/common-functionality/common-functionality.component';
import { Grupo } from 'src/app/core/models/domain/Grupo';
import { ServidorResponseDTO } from 'src/app/core/models/domain/ServidorResponseDTO';
import { criarMetaDadosGrupo } from 'src/app/core/modules/custom-form/utils/meta-dados-util';
import { getFormGroup } from './util/grupo-util';

@Component({
  selector: 'app-grupo',
  templateUrl: './grupo.component.html',
  styleUrls: ['./grupo.component.css']
})
export class GrupoComponent extends CommonFunctionalityComponent implements OnInit {

  // Variável do modelo do grupo
  @Input()
  grupo: Grupo;

  @Input()
  dadosServidor: ServidorResponseDTO;

  // Formulário de Passo
  @Input()
  stepFormGroup: FormGroup;

  @Input()
  chamado: boolean = false;

  @Input()
  grupoChamador: Grupo;

  // Form de grupo
  grupoControl: FormGroup;

  // Guarda a referência na view
  hostView: ViewRef;

  // Componente de ref
  ref!: ComponentRef<GrupoComponent>;

  removivel: boolean = false;

  @Input()
  vcr: ViewContainerRef;

  override ngOnInit(): void {

    // Inicialização do Grupo
    this.initGrupo();

  }

  // Método que inicializa o grupo e adicionar no formulário do passo
  initGrupo() {

    if (this.grupo?.campos) {

      this.grupoControl = (!this.grupoControl) ? new FormGroup([]) : this.grupoControl;

      let formGroup = (!this.chamado) ? this.stepFormGroup : getFormGroup(this.stepFormGroup, this.grupoChamador);
      formGroup.addControl(this.grupo.label, this.grupoControl);

      this.grupoControl.metaDados = criarMetaDadosGrupo(this.grupo);

    }

  }

}
