import { CampoEnum } from "../../step/grupo/campo/util/campo-enum";
import { ValorConclusao } from "./valor-conclusao";

export class CampoConclusao {

    nome: string;
    label: string;
    valor: ValorConclusao[] = [];
    tipoComponente: CampoEnum;
    obrigatorio: boolean;

    constructor(nome: string, label: string, valor: ValorConclusao[], tipoComponente: CampoEnum, obrigatorio: boolean) {
        this.nome = nome;
        this.label = label;
        this.valor = valor;
        this.tipoComponente = tipoComponente;
        this.obrigatorio = obrigatorio;
    }

}
