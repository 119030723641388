import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FilesRequerimento } from 'src/app/core/models/domain/FilesRequerimento';
import { RequerimentoService } from 'src/app/core/services/requerimento.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-validacao',
  templateUrl: './dialog-validacao.component.html',
  styleUrls: ['./dialog-validacao.component.css']
})
export class DialogValidacaoComponent implements OnInit {

  public id: string;
  public protocolo: string;
  observacao: string;
  filespdf: FilesRequerimento[];
  imagens: FilesRequerimento[];

  /*imagens: string[] = [
    'https://via.placeholder.com/500x300?text=Documento+1',
    'https://via.placeholder.com/500x300?text=Documento+2',
    'https://via.placeholder.com/500x300?text=Documento+3',
    'https://via.placeholder.com/500x300?text=Documento+4',
    'https://via.placeholder.com/500x300?text=Documento+5'
  ];*/

  constructor(private requerimentoService: RequerimentoService, private http: HttpClient){
    this.filespdf = [];
  }

  ngOnInit(): void {
    //this.carregarImagens()
    this.filespdf.push({ name: 'requerimento_' + this.protocolo, url: environment.apiUrlFile + '/file/files/requerimento_' + this.protocolo + '.pdf'});
  }





  cancelarRequerimento() {

    const body = {
      id: this.id,
      observacao: this.observacao,
    }
    
    if(confirm("Você está cancelando o requerimento de protocolo: "+this.protocolo+". Essa operação não pode se desfeita. Confirma a operação?")) {
      this.requerimentoService.cancel(body).subscribe(() => {
    });

  }

  }

  /*carregarImagens() {
    const quantidadeImagens = 5; // Defina o número de imagens que deseja carregar
    const apiKey = 'sua_api_key'; // Insira sua API key do Unsplash aqui

    for (let i = 0; i < quantidadeImagens; i++) {
      this.http.get<any>(`https://api.unsplash.com/photos/random?query=document&client_id=${apiKey}`)
        .subscribe(response => {
          this.imagens.push(response.urls.regular);
        });
    }
  }*/

}
