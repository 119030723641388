<div class="container" id="areaBusca">

    <form #formConsulta="ngForm" [formGroup]="formularioConsulta" (ngSubmit)="consultar()" class="needs-validation"
        novalidate>
        
        <div class="card shadow">


            <div class="card-body">
                <div class="row g-3">
                    <mat-card-head>
                        <mat-card-title>
                            Consulta externa
                        </mat-card-title>
                      </mat-card-head>

                    <div class="col-md-4">
                       
                    </div>
                    <div class="col-md-8">
                    </div>

                    <div class="col-md-4">
                        <label for="emailRequerente" class="form-label">E-mail</label>
                        <input type="text" class="form-control" id="emailRequerente" formControlName="emailRequerente">
                        <div class="alert alert-danger p-2 "
                            *ngIf="form.emailRequerente.touched && form.emailRequerente.invalid" role="alert">
                            <span *ngIf="form.numeroProtocolo.errors.required">
                                Por favor, informe o e-mail.
                            </span>
                        </div>
                    </div>
                    <div class="col-md-8">
                    </div>
                    <div class="col-md-4">
                        <label class="form-label" for="numeroSEI">Token</label>
                        <textarea id="numeroSEI" name="token" type="text" formControlName="token" class="form-control" rows="5">
                        </textarea>
                        <div class="alert alert-danger p-2 " *ngIf="form.token.touched && form.token.invalid"
                            role="alert">
                            <span *ngIf="form.numeroProtocolo.errors.required">
                                Por favor, informe o token.
                            </span>
                        </div>
                    </div>
                    <div class="col-md-8">
                    </div>
                    <div class="col-md-4">
                        <label for="numeroProtocolo" class="form-label">N° Protocolo</label>
                        <input type="text" class="form-control" id="numeroProtocolo" formControlName="numeroProtocolo">
                        <div class="alert alert-danger p-2 "
                            *ngIf="form.numeroProtocolo.touched && form.numeroProtocolo.invalid" role="alert">
                            <span *ngIf="form.numeroProtocolo.errors.required">
                                Por favor, informe o número do Protocolo.
                            </span>
                        </div>
                    </div>
                    <div class="col-md-8">
                    </div>
                    <div class="col-md-4">
                        <div *ngIf="mostrarErroNoFormulario" class="alert alert-danger" role="alert">
                            Por favor, preencha todos os campos obrigatórios corretamente.
                        </div>
                    </div>

                    <div class="col-12">
                        <button class="btn btn-primary me-3" type="submit">Buscar</button>
                        <button class="btn btn-danger" type="reset" (click)="limpar()">Limpar</button>


                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="container text-center" id="listagemRequerimentos" *ngIf="requerimentos!=null && requerimentos.length != 0">

    <div class="card bg-transparent border-0 row row-cols-auto g-0 text-center row-requerimentos-header">
        <!-- <div class="col-md-1 h-100 vertical-center">
            Requerimento
        </div> -->

        <div class="col-md-2 h-100 vertical-center">
            Nome
        </div>
        <div class="col-md-1 h-100 vertical-center no-mobile">
            Status
        </div>
        <div class="col-md-2 h-100 vertical-center no-mobile">
            Tipo
        </div>
        <div class="col-md-1 h-100 vertical-center no-mobile">
            N° Protocolo
        </div>
        <div class="col-md-2 h-100 vertical-center no-mobile">
            N° SEI
        </div>
        <div class="col-md-1 h-100 vertical-center no-mobile">
            Data Abertura
        </div>
        <div class="col-md-1 h-100 vertical-center ">
            Ações
        </div>
    </div>
    <ng-container *ngIf="!carregando">
        <div class="accordion requerimentos accordion-flush" id="accordionFlushExample">
            <ng-container *ngFor="let dado of requerimentos">

                <div class="card mb-2 shadow">
                    <div
                        class="accordion-item-- flex-column row row-cols-auto text-center g-0 row-requerimentos p-0 my-2">
                        <!--  <div class="col-md-1 h-100 vertical-center requerimento-id">
                            {{ requerimento.id }}
                        </div> -->
                        <div class="col-md-2 h-100 vertical-center">
                            {{ dado.idRequerimento.nomeRequerente }}
                        </div>

                        <div class="col-md-1 h-100 vertical-center no-mobile">
                            <span class="badge" *ngIf="dado.idRequerimento.status!=null" [ngClass]="{
                            'text-bg-success': dado.idRequerimento.status.id === 1,
                            'text-bg-primary': dado.idRequerimento.status.id === 2,
                            'text-bg-info': dado.idRequerimento.status.id === 3,
                            'text-bg-secondary': dado.idRequerimento.status.id === 4,
                            'text-bg-danger': dado.idRequerimento.status.id === 5
                            }
                            ">{{ dado.idRequerimento.status.descricao }}</span>
                        </div>
                        <div class="col-md-2 h-100 overflow-hidden vertical-center no-mobile">

                            {{ dado.idRequerimento.tipoRequerimento.nome }}
                        </div>
                        <div class="col-md-1 h-100 vertical-center no-mobile">
                            {{ dado.idRequerimento.numeroProtocolo }}
                        </div>
                        <div class="col-md-2 h-100 vertical-center no-mobile">
                            {{ dado.idRequerimento.numeroSEI }}
                        </div>
                        <div class="col-md-1 h-100 vertical-center no-mobile">
                            {{ dado.idRequerimento.dataAbertura | date: 'dd/MM/yyyy hh:mm:ss' }}
                        </div>
                        <div class="col-md-1 h-100 vertical-center float-end">
                            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                aria-current="page" [routerLink]="'/requerimento/detalhes/' + dado.idRequerimento.id"><i
                                    class="bi bi-file-earmark-text" matTooltip="Visualizar"></i></a>
                        </div>
                    </div>
                    <div id="flush-collapseOne-{{ dado.idRequerimento.id }}"
                        class="card-body-- accordion-collapse collapse"
                        attr.aria-labelledby="flush-headingOne-{{dado.idRequerimento.id}}"
                        data-bs-parent="#accordionFlushExample">
                        Detalhes

                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>

<div class="container text-center" id="listagemRequerimentos" *ngIf="requerimentos!=null && requerimentos.length == 0">
    <br>
    <ng-container>
        <div class="alert alert-danger" role="alert">
            Algum dado informado é inválido. Caso tenha perdido o token ou acesso ao e-mail cadastrado, enviar um e-mail
            para atendimento@sgp.uerj.br com título “perda de acesso ao e-mail".
        </div>
    </ng-container>
</div>

<div *ngIf="carregando" class="container text-center mt-5">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>



<!-- <div class="accordion requerimentos accordion-flush" id="accordionFlushExample">

    <div class="card mb-2 p-2">
        <div class="accordion-item ">
            <div class="accordion-header d-flex flex-row" id="flush-headingOne-2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne-2" aria-expanded="false" aria-controls="flush-collapseOne">
                    #1
                </button>
                <div class="flex-column flex-grow-1 h-100">
                    <div class="">Tipo</div>
                    <div class="">Flex item</div>
                </div>
                <div class="p-2 flex-shrink-1">
                    <button class="accordion-button collapsed p-1" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne-2" aria-expanded="false" aria-controls="flush-collapseOne">
                    </button>
                </div>
            </div>
            <div id="flush-collapseOne-2" class="accordion-collapse collapse" aria-labelledby="flush-headingOne-2"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the
                    <code>.accordion-flush</code> class. This is the first item's accordion body.
                </div>
            </div>
        </div>
        <div class="accordion-item ">
            <div class="accordion-header d-flex flex-row" id="flush-headingOne-3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne-3" aria-expanded="false" aria-controls="flush-collapseOne">
                    #1
                </button>
                <div class="flex-column flex-grow-1 h-100">
                    <div class="">Tipo</div>
                    <div class="">Flex item</div>
                </div>
                <div class="p-2 flex-shrink-1">
                    <button class="accordion-button collapsed p-1" type="button" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne-3" aria-expanded="false" aria-controls="flush-collapseOne">
                    </button>
                </div>
            </div>
            <div id="flush-collapseOne-3" class="accordion-collapse collapse" aria-labelledby="flush-headingOne-3"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the
                    <code>.accordion-flush</code> class. This is the first item's accordion body.
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-2 p-2">
        <div class="accordion-item">
            <span class="accordion-header" id="flush-headingOne-3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne-3" aria-expanded="false" aria-controls="flush-collapseOne">
                    Accordion Item #1
                </button>
            </span>
            <div id="flush-collapseOne-3" class="accordion-collapse collapse" aria-labelledby="flush-headingOne-3"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the
                    <code>.accordion-flush</code> class. This is the first item's accordion body.
                </div>
            </div>
        </div>
    </div>
</div> -->