<div class="mt-2 mb-2">

    <mat-label class="labelFile">{{ 'Anexo ' + campo.label }}</mat-label>

    <p>
        <a mat-button (click)="fileInput.click();$event.preventDefault();" style="display: block;"
            class="mdc-button__label">Selecionar arquivo...</a>
    </p>
    <input hidden id="{{ campo.id }}" type="file" [formControl]="campoInputControl" name="{{ campo.nome }}"
        (change)="onFileSelected($event);" #fileInput />

    <div *ngIf="campoFileControl?.value?.file">
        <p><span style="font-style: italic;">
                {{ campoFileControl?.value?.file.name }}
            </span>

            <!--<span>(
                <a href="" (click)="visualizarArquivo=!visualizarArquivo;$event.preventDefault();">{{ visualizarArquivo ? ' Ocultar Arquivo ' : ' Visualizar Arquivo ' }}</a>
                )
            </span>-->

        </p>

    </div>

    <div id="file-wrapper" class="preview-image"
        *ngIf="imagePreview !== '' && imagePreview && campoInputControl.valid && visualizarArquivo">
        <img [src]="imagePreview" [alt]="campoInputControl.value.title">
    </div>

    <!-- Errors -->
    <mat-error *ngIf="campoFileControl?.invalid">{{messageError ? messageError : getErrorMessage()}}</mat-error>

</div>