import { Component } from '@angular/core';

@Component({
  selector: 'app-consulta-requerimento',
  templateUrl: './consulta-requerimento.component.html',
  styleUrls: ['./consulta-requerimento.component.css']
})
export class ConsultaRequerimentoComponent {

}
