<h2 mat-dialog-title>Validação de requerimento</h2>
<mat-dialog-content class="mat-typography">

  <!--<div class="row" *ngFor="let filepdf of filespdf">-->
  <div class="row">
    <div class="col-md-12" >
      <div class="pdf-container" style="height: 600px; overflow-y: auto;">
        <pdf-viewer
          [src]="filespdf[0].url"
          [rotation]="0"
          [original-size]="false"
          [show-all]="true"
          [fit-to-page]="false"
          [zoom]="1"
          [zoom-scale]="'page-width'"
          [stick-to-page]="false"
          [render-text]="true"
          [external-link-target]="'blank'"
          [autoresize]="true"
          [show-borders]="true"
          style="width: 100%; height: 100%;"
        ></pdf-viewer>
      </div>
    </div>
    <!--</div>-->
   <!-- <div class="col-md-6">
      <div class="image-container" style="height: 600px; overflow-y: auto;">
        <div *ngFor="let imagem of imagens">
          <img [src]="imagem.url" alt="Imagem do documento" style="width: 100%; height: auto; margin-bottom: 10px;">
        </div>
      </div>
    </div> -->
  </div>

  <div class="row" style="margin-top: 10px;">
    <div class="col-md-12">
      <form class="form" #form="ngForm">
        <mat-form-field style="width: 100%;">
          <mat-label>Observações:</mat-label>
          <textarea matInput style="height: 200px;" [(ngModel)]="observacao" name="observacao"></textarea>
        </mat-form-field>
      </form>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>

  <div class="mb-3">
    <button class="btn btn-success green-button mx-2" [mat-dialog-close]="true" >Dados estão corretos. <i class="bi bi-check"></i></button>
    <button class="btn btn-danger mx-2" [mat-dialog-close]="false" (click)="cancelarRequerimento()">Há irregularidade no requerimento <i class="bi bi-x"></i></button>
    <button class="btn btn-secondary mx-2" [mat-dialog-close]="null" data-bs-dismiss="modal">Fechar</button>
  </div>

</mat-dialog-actions>
