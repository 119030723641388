import { Component } from '@angular/core';

@Component({
  selector: 'app-inicio-externo',
  templateUrl: './inicio-externo.component.html',
  styleUrls: ['./inicio-externo.component.css']
})
export class InicioExternoComponent {

}
