import { Component, ComponentRef, Input, OnInit, ViewContainerRef, ViewRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, map, startWith } from 'rxjs';
import { CommonFunctionalityComponent } from 'src/app/core/components/common-functionality/common-functionality.component';
import { Campo } from 'src/app/core/models/domain/Campo';
import { Grupo } from 'src/app/core/models/domain/Grupo';
import { ServidorResponseDTO } from 'src/app/core/models/domain/ServidorResponseDTO';
import { Opcao } from 'src/app/core/models/domain/opcao';
import { criarMetaDadosCampo } from 'src/app/core/modules/custom-form/utils/meta-dados-util';
import { isAutoComplete, isFile } from './util/campo-util';
import { getErrorMessage } from './util/error-handler';

export interface User {
  name: string;
}

@Component({
  selector: 'app-campo',
  templateUrl: './campo.component.html',
  styleUrls: ['./campo.component.css']
})
export class CampoComponent extends CommonFunctionalityComponent implements OnInit {

  // Variáveis do modelo
  @Input()
  campo: Campo;

  @Input()
  grupo: Grupo;

  @Input()
  dadosServidor: ServidorResponseDTO = null;

  // Formulário do grupo
  @Input()
  grupoControl: FormGroup;

  // Formulário do Passo do grupo atual
  @Input()
  stepFormGroup: FormGroup;

  // Controle do campo específico
  campoControl: FormControl = new FormControl('');

  // Variável que representa um elemento na UI
  hostView: ViewRef;

  // Ref do componente
  ref!: ComponentRef<CampoComponent>;

  // Referência na UI para inclusão dos campos
  @Input()
  vcr: ViewContainerRef;

  // Autocomplete
  options: Opcao[];
  filteredOptions: Observable<Opcao[]>;

  removivel: boolean = false;

  constructor(private formBuilder: FormBuilder, public override router: Router) {
    super(router);
  }

  override ngOnInit(): void {
    // Inicialização do Campo
    this.initCampo();
  }

  // Inicia o campo
  initCampo() {

    let valor = '';
    if (this.dadosServidor) {
      valor = this.dadosServidor[this.campo.nome as keyof typeof this.dadosServidor] as string;
    }

    // Se tiver o dado correspondente, preencha e desabilita o campo
    if (valor) {
      this.campo.valor = valor;
      this.campoControl.setValue(valor);
      this.campoControl.disable();
    } else {
      // Se n tiver coloca os validadores
      this.campoControl.setValidators(this.getValidators());
    }

    // Verifica se o campo chama outros grupos por meio das opções
    const chamaGrupo = this.campo.opcoes?.some(o => {
      return o.opcaoGrupo?.length > 0
    })

    if (chamaGrupo) {
      this.campo.chamaGrupo = true;
    }

    // Inclui o campo no formulário do grupo, para campos multivalorados e files terá uma
    // tratativa específica pelo próprio componente
    if (!this.campo.multiValorado && !isFile(this.campo) && !chamaGrupo) {
      this.grupoControl.addControl(this.campo.nome, this.campoControl);
    }

    // Se for autocomplete
    if (isAutoComplete(this.campo)) {

      this.options = this.campo.opcoes;

      this.filteredOptions = this.campoControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.name;
          return name ? this._filter(name as string) : this.options.slice();
        }),
      );
    }

    this.campoControl.metaDados = criarMetaDadosCampo(this.campo);

  }

  // Prover os validadores para o campo de acordo com suas restrições
  getValidators(): any[] {

    let validators = [];

    if (this.campo.obrigatorio) {
      validators.push((this.campo.tipoComponente == 'checkbox') ? Validators.requiredTrue : Validators.required);
    }

    if (this.campo.minLength) {
      validators.push(Validators.minLength);
    }

    if (this.campo.tamanhoMaximo) {
      validators.push(Validators.maxLength(this.campo.tamanhoMaximo));
    }

    if (this.campo.expressaoRegular) {
      validators.push(Validators.pattern(this.campo.expressaoRegular));
    }

    return validators;

  }

  // Método para exclusão do campo da UI e no array do campo pertinente
  removerCampo() {

    const index = this.vcr.indexOf(this.hostView);
    if (index != -1) {
      this.vcr.remove(index);
      let campoArray = this.grupoControl.get(this.campo.nome) as FormArray;
      campoArray.removeAt(index);
    }

  }

  // Mensagem de error
  getErrorMessage(): string {
    return getErrorMessage(this.campo, null, this.campoControl);
  }

  // Método para exibição no autocomplete
  displayFn(opcao: Opcao): string {
    return opcao?.label ? opcao.label : '';
  }

  // Filtragem no autocomplete
  private _filter(name: string): Opcao[] {

    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.valor.toLowerCase().includes(filterValue));

  }

}
