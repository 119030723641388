import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { RequerimentoService } from 'src/app/core/services/requerimento.service';
import { Requerimento } from 'src/app/core/models/domain/requerimento-class.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { query } from '@angular/animations';
import { PaginacaoDTO } from 'src/app/core/models/domain/paginacao-dto.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmacaoComponent } from 'src/app/core/components/gestao/datatable/dialogs/dialog-confirmacao/dialog-confirmacao.component'
import { DialogValidacaoComponent } from 'src/app/core/components/gestao/datatable/dialogs/dialog-validacao/dialog-validacao/dialog-validacao.component';
import { TipoRequerimentoService } from 'src/app/core/services/tipo.requerimento.service';
import { TipoRequerimento } from 'src/app/core/models/domain/TipoRequerimento';
import { Observable, first, map, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserResponse } from 'src/app/core/models/authentication/responses/user-response';
import { User } from 'src/app/core/models/user';
import { UserService } from 'src/app/core/services/user.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AuthenticationHelper } from 'src/app/core/helpers/authentication.helper';
import { FileService } from 'src/app/core/services/file.service';
import { FilesRequerimento } from 'src/app/core/models/domain/FilesRequerimento';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { StatusService } from 'src/app/core/services/status.service';
import { DialogEdicaoComponent } from 'src/app/core/components/gestao/datatable/dialogs/dialog-edicao/dialog-edicao.component';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit, AfterViewInit {


  //filepdf = 'http://152.92.220.63:8080/files/segunda_via (1).pdf';

  //requerimentos: Requerimento[] = [];
  carregando: boolean;
  files: FilesRequerimento[];
  requerimentos: any;
  paginacaoResponse: any;
  paginas: number[] = [];
  tipoRequerimentoInput: any;
  tipoRequerimento: TipoRequerimento;
  options: TipoRequerimento[] = [];
  filteredOptions: Observable<TipoRequerimento[]>;
  requerimento: any;
  numeroSEI: string;

  filespdf: FilesRequerimento[] = [];
  imagens: FilesRequerimento[] = [];

  myControl = new FormControl('');

  selectedOption: any;

  loading = false;
  userResponse: UserResponse;
  userFromApi?: User;

  isAdmin = false;

  paginaAtual: number = 1;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  formularioConsulta = new FormGroup({
    matricula: new FormControl('',[Validators.required, Validators.minLength(3), Validators.maxLength(8)]),
    numeroProtocolo: new FormControl(''),
    quantidade: new FormControl(''),
    pagina: new FormControl(''),
    status: new FormControl(''),
    protocolo: new FormControl(''),
    numeroSEI: new FormControl(''),
    cpf: new FormControl(''),
    dataInicio: new FormControl(''),
    dataFim: new FormControl(''),
    tipoRequerimento: new FormControl(''),
    nomeRequerente: new FormControl('')
  });
  displayValue: string;

  statusOptions : any;
  isPadrao: boolean;


  constructor(
    private formBuilder: FormBuilder,
    private requerimentoService: RequerimentoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private tipoRequerimentoService: TipoRequerimentoService,
    private statusService: StatusService,
    private fileService: FileService,
    private userService: UserService,
    private authenticationHelper: AuthenticationHelper,
    private authenticationService: AuthenticationService

  ) {

    let logado = authenticationHelper.logado();

    this.isAdmin = logado ? authenticationHelper.isAdmin() : false;
    this.isPadrao = logado ? authenticationHelper.isPadrao() : false;
    
    this.userResponse = <UserResponse>this.authenticationService.userValue;

    /*  this.formularioConsulta = this.formBuilder.group({
       matricula: [''],
       numeroProtocolo: [''],
       quantidade: ['']
     }); */
  }

  ngOnInit(): void {
    this.carregando = false;
    // Requerimentos
    this.listarRequerimentos();

    this.loading = true;

    this.tipoRequerimentoService.findAll().subscribe(data => {
      const itensComVisibilidade = data.filter(item => item.visivel === true);
      this.options = [new TipoRequerimento(null, null, "Todos", true, null, null, null), ...itensComVisibilidade];
    });

    this.statusService.findAll().subscribe(data => {
      this.statusOptions = data;
    }); 
    

  

    this.tipoRequerimento = null;
    this.tipoRequerimentoInput = null;

    // Initiation filters
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

  }

  criarPaginator(){
    const customPaginatorIntl = new MatPaginatorIntl();
    customPaginatorIntl.itemsPerPageLabel = 'Requisitos por página:';
    customPaginatorIntl.firstPageLabel = 'Primeira página';
    customPaginatorIntl.nextPageLabel = 'Página seguinte';
    customPaginatorIntl.previousPageLabel = 'Página anterior';
    customPaginatorIntl.lastPageLabel = 'Última página';
    customPaginatorIntl.getRangeLabel = function (page: number, pageSize: number, length: number) {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `Exibindo ${start} a ${end > length ? length : end} de ${length} itens`;
    };
    this.paginator._intl = customPaginatorIntl;
  }

  


  carregarArquivos(protocolo: string){

    this.filespdf = [];
    this.imagens = [];

    this.fileService.get().subscribe(data => {

      this.files = data;

      data.forEach(file => {
        
        if (file.url.endsWith('.pdf')) {
          this.filespdf.push(file);
        } else {
          this.imagens.push(file);
        }

      });
    });

  }

  private _filter(value: string): any[] {
    const filterValue = (typeof value === 'string') ? value.toLowerCase() : this.requerimento;

    if (filterValue === null && this.requerimento === null) {
      return null;
    }

    if (filterValue === '') {
      return null;
    }

    return this.options.filter((option) =>
      option.nome.toLowerCase().includes(filterValue)
    );
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit');

    $('#dataInicio').mask('99/99/9999');
    $('#dataFim').mask('99/99/9999');
    $('#cpf').mask('000.000.000-00');
    $('#matricula').mask('00000000');
    
  }

  listarRequerimentos() {
    this.carregando = true;
    const request = {

      
      filtro: {
        matriculaRequisitado: this.formularioConsulta.controls.matricula.value || '',
        numeroProtocolo: this.formularioConsulta.controls.numeroProtocolo.value || '',
        status: this.formularioConsulta.controls.status.value || '',
        protocolo: this.formularioConsulta.controls.protocolo.value || '',
        numeroSEI: this.formularioConsulta.controls.numeroSEI.value || '',
        cpfRequisitado: this.isPadrao? this.userResponse.cpf.toString().replace(/\.|-/gm,'') :  this.formularioConsulta.controls.cpf.value.toString().replace(/\.|-/gm,'') || '',
        dataInicio: this.formularioConsulta.controls.dataInicio.value || '',
        dataFim: this.formularioConsulta.controls.dataFim.value || '',
        tipoRequerimento: this.tipoRequerimentoInput || '',
        nomeRequerente: this.formularioConsulta.controls.nomeRequerente.value || '',
      },

      quantidade: this.formularioConsulta.value.quantidade == null || this.formularioConsulta.value.quantidade == '' ? '10' : this.formularioConsulta.value.quantidade,
      pagina: this.formularioConsulta.value.pagina == null || this.formularioConsulta.value.pagina == '' ? '1' : this.formularioConsulta.value.pagina
    }
    /* this.requerimentoService.findAll().subscribe(data => {
      this.requerimentos = data;
    }); */
    console.log(request);


  this.requerimentoService.findAllPaginate(request).subscribe(data => {
      this.paginacaoResponse = data;
      this.requerimentos = this.paginacaoResponse.registros
      this.contagemPaginasLocal();
      console.log(["PaginacaoDTO"], this.paginacaoResponse)
      console.log(["Paginas"], this.paginas)
      this.atualizarRotaESetarValores();
    }).add(() => {
      this.carregando = false;
    });
  }

  consultar() {
    // TODO: Use EventEmitter with form value
    //console.warn(this.formularioConsulta.value);
    //console.warn(this.formularioConsulta.value);
    this.listarRequerimentos();
    this.atualizarRotaESetarValores();
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {

    let nomeRetorno : string;

    console.log(event);
    if (event.option && event.option.value) {
      this.tipoRequerimentoInput = event.option.value.id ? event.option.value.id.toString() : null;



      if (event.option.value.tipoRequerimentoPai) {
        nomeRetorno = event.option.value.tipoRequerimentoPai.nome+ '-'+ event.option.value.nome;
      }else{
        nomeRetorno = event.option.value.nome;
      }


      this.tipoRequerimento = event.option.value.tipoRequerimentoPai ? event.option.value.tipoRequerimentoPai+ '-'+ event.option.value.nome :  event.option.value.nome;
      // Defina o valor do campo de entrada aqui
      this.myControl.setValue(nomeRetorno);
    }
  }

  onChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    // A lógica que você deseja quando o input muda

    if(inputValue === '')
    this.tipoRequerimentoInput = null;

  }
  
  
  

  irParaPagina(pagina: number) {
    this.paginaAtual = pagina;
    this.formularioConsulta.get("pagina").setValue(pagina.toString());
    this.consultar();
    //console.log(this.formularioConsulta.value);
  }

  irParaProxima(pagina: number) {
    this.paginaAtual = pagina+1;
    this.formularioConsulta.get("pagina").setValue(pagina.toString());
    this.consultar();
    //this.formularioConsulta.get("pagina").setValue(this.paginacaoResponse.proximaPagina.toString());
   // this.consultar();
    //console.log(this.formularioConsulta.value);
  }

  voltarParaAnterior(pagina: number) {
    this.paginaAtual = pagina-1;
    this.formularioConsulta.get("pagina").setValue(pagina.toString());
    this.consultar();
    //this.formularioConsulta.get("pagina").setValue(this.paginacaoResponse.paginaAnterior.toString());
    //this.consultar();
    //console.log(this.formularioConsulta.value);
  }

  alterarQuantidade($event: any) {
    this.formularioConsulta.get("quantidade").setValue($event.target.value.toString());
    this.consultar();
  }

  limpar() {
    this.formularioConsulta.reset();
    this.tipoRequerimento = null;
    this.tipoRequerimentoInput = null;
    this.router.navigateByUrl('/requerimentos-responsivo');
    this.consultar();
  }

  // Defina a página atual


// Método para calcular as páginas visíveis com base na página atual
getPaginasVisiveis(): number[] {
  const paginasVisiveis = [];
  const paginasTotais = this.paginacaoResponse.totalPaginas;

  const paginasAntes = 5; // Páginas visíveis antes da página atual
  const paginasDepois = 4; // Páginas visíveis depois da página atual

  if (paginasTotais <= 10) {
    for (let i = 1; i <= paginasTotais; i++) {
      paginasVisiveis.push(i);
    }
  } else {
    if (this.paginaAtual <= paginasAntes) {
      for (let i = 1; i <= 10; i++) {
        paginasVisiveis.push(i);
      }
    } else if (this.paginaAtual >= paginasTotais - paginasDepois) {
      for (let i = paginasTotais - 9; i <= paginasTotais; i++) {
        paginasVisiveis.push(i);
      }
    } else {
      for (let i = this.paginaAtual - 5; i <= this.paginaAtual + 4; i++) {
        paginasVisiveis.push(i);
      }
    }
  }

  return paginasVisiveis;
}


// No seu componente TypeScript
deveExibirReticenciasInicio(): boolean {
  const paginasVisiveis = this.getPaginasVisiveis();
  return paginasVisiveis[0] > 1;
}

validateMatricula () {
  const matriculaControl = this.formularioConsulta.get('matricula');
  if (matriculaControl && matriculaControl.value.length < 3) {
    alert('A matrícula deve ter pelo menos 3 dígitos.');
    matriculaControl.setValue('');
  }
}

deveExibirReticenciasFim(): boolean {
  const paginasTotais = this.paginacaoResponse.totalPaginas;
  const paginasVisiveis = this.getPaginasVisiveis();
  return paginasVisiveis[paginasVisiveis.length - 1] < paginasTotais;
}




  contagemPaginasLocal() {
    this.paginas.length = 0
    for (var i = 1; i <= this.paginacaoResponse.totalPaginas; i++) {
      this.paginas.push(i);
    }
  }

  atualizarRotaESetarValores() {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          quantidade: this.formularioConsulta.value.quantidade == null || this.formularioConsulta.value.quantidade == '' ? '10' : this.formularioConsulta.value.quantidade,
          pagina: this.formularioConsulta.value.pagina == null || this.formularioConsulta.value.pagina == '' ? '1' : this.formularioConsulta.value.pagina,
          matriculaRequisitado: this.formularioConsulta.value.matricula == null || this.formularioConsulta.value.matricula == '' ? '' : this.formularioConsulta.value.matricula,
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

    //get params
    this.activatedRoute.paramMap.subscribe((params) => {
      console.log(['params'], params);
    });

    this.activatedRoute.queryParams.subscribe((query) => {
      console.log(['query'], query);
      this.formularioConsulta.get("quantidade").setValue(query.quantidade);
      this.formularioConsulta.get("pagina").setValue(query.pagina);
      this.formularioConsulta.get("matricula").setValue(query.matricula);
    });
  }

  criarRequerimento(id: number, element: any) {
    this.openDialog('Criar', id.toString(), element)
  }

  validarRequerimento(id: number, element: any) {
    //this.carregarArquivos(element.numeroProtocolo);
    this.openDialog('Validar', id.toString(), element)
  }

  baixarZip(id: number, numeroProtocolo: any) {

    const link = document.createElement('a');
    link.setAttribute('href', environment.apiUrlFile + '/file/files/requerimento_' + numeroProtocolo + '.zip');
    link.setAttribute('download', 'requerimento_' + numeroProtocolo + '.zip');
    document.body.appendChild(link);
    link.click();
    link.remove();

  }

  editarNumeroSEI(id: number, element: any) {
    this.openDialog('EditarNumeroSEI', id.toString(), element)
  }

  cancelarRequerimento(id: number, element: any) {
    this.openDialog('Cancelar', id.toString(), element)
  }

  finalizarRequerimento(id: number, element: any) {
    this.openDialog('Finalizar', id.toString(), element)
  }

  editarRequerimento(id: number, element: any) {
    this.openDialog('EditarNumeroSEI', id.toString(), element)
  }

  copiarMensagm(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  openDialog(acao: string, id: string, element: any) {

    if (acao == "Cancelar") {
      const dialogRef = this.dialog.open(DialogConfirmacaoComponent);
      dialogRef.componentInstance.acao = "cancelar";    
          dialogRef.afterClosed().subscribe(observacao => {
            if(confirm("Você está cancelando o requerimento de protocolo: "+element.numeroProtocolo+". Essa operação não pode se desfeita. Confirma a operação?")) {

              if (observacao) {
                this.requerimentoService.cancel({ id: id, observacao: observacao }).subscribe(() => {
                  this.consultar();
                });
              }
          }
        });
    }

    if (acao == "Finalizar") {
      const dialogRef = this.dialog.open(DialogConfirmacaoComponent);
      dialogRef.componentInstance.acao = "finalizar";

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.requerimentoService.close(id, element).subscribe(() => {
            this.consultar();
          });
        }
      });
    }

    if (acao == "Validar") {

      const dialogRef = this.dialog.open(DialogValidacaoComponent, {autoFocus: false});
      dialogRef.componentInstance.id = id;
      dialogRef.componentInstance.protocolo = element.numeroProtocolo;
      dialogRef.componentInstance.filespdf = this.filespdf;//[new FilesRequerimento(null, this.files[0].url)];// this.files[0].url; 
      dialogRef.componentInstance.imagens = this.imagens;//[new FilesRequerimento(null, this.files[2].url)];


      dialogRef.afterClosed().subscribe(result => {

        if (result != null) {
          if (result) {
            this.requerimentoService.validate(element).subscribe(() => {
              this.consultar();
            });
          }
          if (!result) {
                this.consultar();
          }
        }
      });
    }

    if(acao == "EditarNumeroSEI") {

      const dialogRef = this.dialog.open(DialogEdicaoComponent, {
        width: '400px'
      });

      dialogRef.componentInstance.id = id;
      dialogRef.componentInstance.numeroSEI = this.numeroSEI;

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          Swal.fire(
            'Novo Número do SEI!!',
            result.message,
            'success'
          );
        }
        else{
          Swal.fire(
            'Número do SEI já existe!',
            result.message,
            'error'
          );
        }
      });


      /*dialogRef.afterClosed().subscribe(
        {

          next: data => {
           Swal.fire(
             'Novo Número do SEI!!',
             data.message,
             'success'
           );
           this.consultar();
          },
          error: error => {
           Swal.fire(
             'Operação não concluída!!',
             'Erro ao atualizar o número do SEI.',
             'error'
           )
          }
     
       });*/

    }

  }
}
