import { CampoConclusao } from "./campo-conclusao";

export class GrupoConclusao {

  label: string;
  ordem: number;
  tipoLayout: number;
  campos: CampoConclusao[];
  value: any;

  constructor(label: string, ordem: number, tipoLayout: number, campos: CampoConclusao[]) {
    this.label = label;
    this.ordem = ordem;
    this.tipoLayout = tipoLayout;
    this.campos = campos;
  }

}
