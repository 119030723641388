import { AfterViewInit, Component, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Campo } from 'src/app/core/models/domain/Campo';
import { Grupo } from 'src/app/core/models/domain/Grupo';
import { criarMetaDadosCampo } from 'src/app/core/modules/custom-form/utils/meta-dados-util';
import { CampoComponent } from '../campo.component';

@Component({
  selector: 'app-campo-multivalorado',
  templateUrl: './multi-valorado.component.html',
  styleUrls: ['./multi-valorado.component.css']
})
export class CampoMultiValoradoComponent implements OnInit, AfterViewInit {

  // Variáveis do modelo  
  @Input()
  campo: Campo;

  @Input()
  grupo: Grupo;

  // Variável do grupo atual do campo
  @Input()
  grupoControl: FormGroup;

  // Formulário do passo atual
  @Input()
  stepFormGroup: FormGroup;

  // Armazena os campos multivalorados
  campoArrayControl: FormArray<any>;

  // Guarda a referência na UI onde será criado os campos multivalorados
  @ViewChild("viewContainerRef", { read: ViewContainerRef }) vcr!: ViewContainerRef;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    // Inicializa o campo
    this.initCampoMultivalorado();
  }

  ngAfterViewInit(): void {
    // Adiciona o campo inicial
    this.adicionarCampo(this.campo);
  }

  // Inicializa o campo multivalorado e adiciona o mesmo array e no grupo
  initCampoMultivalorado() {
    this.campoArrayControl = this.formBuilder.array([]);
    this.campoArrayControl.metaDados = criarMetaDadosCampo(this.campo);

    this.grupoControl.addControl(this.campo.nome, this.campoArrayControl);
  }

  // Método que cria o novo componente de campo dinamicamente e adiciona ele na UI e no array do FormControl
  adicionarCampo(campo: Campo) {

    // Criação do componente dinamicamente
    let ref = this.vcr.createComponent(CampoComponent) as ComponentRef<CampoComponent>;

    // Atribuição das variáveis
    ref.instance.campo = campo;
    ref.instance.grupo = this.grupo;
    ref.instance.grupoControl = this.grupoControl;
    ref.instance.stepFormGroup = this.stepFormGroup;
    ref.instance.hostView = ref.hostView;
    ref.instance.vcr = this.vcr;
    ref.instance.removivel = (this.campoArrayControl.length >= 1);
    ref.instance.campoControl.metaDados = criarMetaDadosCampo(this.campo);

    // Inclusção do campo no campo Array, não é incluído o tipo file pois é tratado diferentemente no componente correspondentes
    if (campo.tipoComponente != 'file')
      this.campoArrayControl.push(ref.instance.campoControl);

  }

}
