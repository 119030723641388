import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { StepperOrientation } from '@angular/cdk/stepper';
import { Component, inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AppInjector } from 'src/app/app.module';
import { ServidorResponseDTO } from 'src/app/core/models/domain/ServidorResponseDTO';
import { TipoOperacao } from 'src/app/core/models/domain/TipoOperacao';
import { TipoRequerimento } from 'src/app/core/models/domain/TipoRequerimento';
import { FormControlMetadados } from 'src/app/core/modules/custom-form/models/form-control-metadados';
import { ServidorService } from 'src/app/core/services/servidor.service';
import { TipoRequerimentoService } from 'src/app/core/services/tipo.requerimento.service';
import { CommonFunctionalityComponent } from '../../../common-functionality/common-functionality.component';
import { DialogConfirmacaoComponent } from '../../../gestao/datatable/dialogs/dialog-confirmacao/dialog-confirmacao.component';
import { StatusEnum } from '../status-enum';
import { ConfirmationDialogComponent } from '../utils/confirmation-dialog/confirmation-dialog.component';
import { TipoComponenteAppEnum } from '../utils/tipo-componente-app-enum';
import { CampoConclusao } from './step-conclusao/models/campo-conclusao';
import { GrupoConclusao } from './step-conclusao/models/grupo-conclusao';
import { ValorConclusao } from './step-conclusao/models/valor-conclusao';
import { CampoEnum } from './step/grupo/campo/util/campo-enum';
import { formatCelular, formatCEP, formatCNPJ, formatCPF, formatTelefone, isInputTipo } from './step/grupo/campo/util/campo-util';
import { Step } from './step/step';

@Component({
  selector: 'app-stepper-abertura-requerimento',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperAberturaRequerimentoComponent extends CommonFunctionalityComponent implements OnInit {

  isLinear = true;

  // Variáveis do modelo para seleção
  matricula: Number;
  tipoRequerimento: TipoRequerimento;
  subGrupoRequerimento: TipoRequerimento;
  tipoOperacao: TipoOperacao;

  dadosServidor: ServidorResponseDTO;

  // Orientação do stepper
  orientation: StepperOrientation = 'horizontal';

  // Array que armazena os passos
  steps: Step[];
  stepFormsGroup: FormGroup[] = [];

  // Injeta o serviço do TipoRequerimento e Servidor
  tipoRequerimentoService: TipoRequerimentoService = AppInjector.get(TipoRequerimentoService);
  servidorService: ServidorService = AppInjector.get(ServidorService);

  // Handler para os serviços
  errorHandlerService: any;
  errorMessage: any;

  status: StatusEnum = StatusEnum.INICIO;
  grupos: GrupoConclusao[] = [];
  formData: FormData = new FormData();

  readonly dateFormat = 'DD/MM/YYYY';

  dialogRef = inject(MatDialogRef<DialogConfirmacaoComponent>);

  constructor(public platform: Platform, private activatedRoute: ActivatedRoute, public override router: Router, public dialog: MatDialog) {
    super(router);
  }

  override ngOnInit(): void {

    // Inicializa as variáveis do componente
    this.initVariaveis();

    // Inicializa os Passos
    this.initSteps();

  }

  // Inicialização das variáveis
  initVariaveis() {

    // Recupera o parâmetro data e inicializa as variáveis
    let data = JSON.parse(this.activatedRoute.snapshot.params['data']);

    this.tipoRequerimento = JSON.parse(data['tipoRequerimento']);
    this.subGrupoRequerimento = JSON.parse(data['subGrupoRequerimento']);
    this.tipoOperacao = JSON.parse(data['tipoOperacao']);
    this.dadosServidor = (data['dadosServidor']) ? JSON.parse(data['dadosServidor']) : null;

    // Verifica qual a orientação do stepper para dispositivos móveis
    this.orientation = (this.platform.ANDROID || this.platform.IOS) ? 'vertical' : 'horizontal';


  }

  // Inicialização dos passos
  initSteps() {

    // Obtem3 os passos do serviço de tipo de requerimento
    this.tipoRequerimentoService.getSteps({
      id: (this.subGrupoRequerimento?.id) ? this.subGrupoRequerimento?.id : this.tipoRequerimento.id,
      idOperacao: this.tipoOperacao.id
    }).subscribe({

      next: (data) => {

        this.steps = data;

      },
      error: (e) => {

        this.errorHandlerService.handle(e);
        this.errorMessage = e.status == 0 ? 'Erro ao listar os passos' : e.message;
        console.log(e.status == 0 ? 'Sem comunicação com a API Formulário' : e.status + e.message);

      },
      complete: () => {
        console.info('Carregado a lista de Tipo de Requerimentos.');
      }

    });

  }

  public adicionarStepFormGroup(stepFormGroup: FormGroup): void {
    this.stepFormsGroup.push(stepFormGroup);
  }

  // Método final que é chamado no último passo para o passo de conclusão
  public concluirPreenchimento(): void {

    // Altera o Status do processo de abertura de Requeriment
    this.status = StatusEnum.CONCLUSAO;

    // Zera os grupos
    this.grupos = [];

    // Pra cada passo gera os grupos com seus campos de uma forma recursiva
    this.stepFormsGroup.forEach(formStep => {
      this.gerarGruposConclusao(null, formStep, this.grupos);
    });

  }

  // Método recursivo que gera os grupos com os campos para a etapa de Conclusão / Verificação
  public gerarGruposConclusao(grupoConclusaoKey: GrupoConclusao, auxForm: AbstractControl<any, any>, grupos: GrupoConclusao[]) {

    // Se o form estiver desabilitado ou não possuir metadados sai da iteração
    if (!auxForm.metaDados) {
      return;
    }

    // Definição das variáveis para o algoritmo
    const metaDados = auxForm.metaDados;
    const tipoComponenteAppEnum = metaDados?.tipoComponenteAppEnum;

    let grupoConclusaoArray = grupos?.find(g => {
      return g.label == grupoConclusaoKey?.label
    })?.campos;

    // Declaração da variável que armazenará o grupo corrente
    let grupoConclusao: GrupoConclusao;

    // Verifica qual tipo de Componente é o formulário em questão, se é campo, um array de campos (multivalorado)
    // grupo ou um array de grupos (multivalorado)
    switch (tipoComponenteAppEnum) {

      case TipoComponenteAppEnum.CAMPO:

        this.addCampoConclusao(metaDados, grupoConclusaoArray, auxForm);

        break;

      case TipoComponenteAppEnum.CAMPOS_MULTIVALORADOS:

        const formArray = (auxForm as FormArray);

        // Se o array não estiver no mapa, inicializa o array de campoConclusão 
        if (!grupoConclusaoArray) {
          grupoConclusaoKey.campos = [];
          grupos.push(grupoConclusaoKey);
        }

        if (formArray.controls) {

          // Itera os forms filhos do form atual, serão campos multivalorados
          Object.keys(formArray.controls).forEach(keyArray => {
            this.gerarGruposConclusao(grupoConclusaoKey, formArray.get(keyArray), grupos);
          });

        } else {
          this.addCampoConclusao(metaDados, grupoConclusaoArray, auxForm);
        }
        break;

      case TipoComponenteAppEnum.GRUPO:

        // Caso o grupo não estiver no map, instancia e adiciona no map
        if (!grupoConclusaoArray) {
          grupoConclusaoArray = []
          grupoConclusaoKey = new GrupoConclusao(metaDados.label, metaDados.ordem, metaDados.tipoLayout, grupoConclusaoArray);
          grupos.push(grupoConclusaoKey);
        }

        // Itera os controls/forms do FormGroup
        const auxFormGroup = auxForm as FormGroup;
        Object.keys(auxFormGroup.controls).forEach(key => {
          const element = auxFormGroup.controls[key];
          // ** Mudar quando implementar o file na conclusão
          grupoConclusaoKey = (element instanceof FormGroup && element.metaDados.tipoComponente != CampoEnum.file) ?
            new GrupoConclusao(element.metaDados.label, element.metaDados.ordem, element.metaDados.tipoLayout, []) : grupoConclusaoKey;
          this.gerarGruposConclusao(grupoConclusaoKey, auxFormGroup.controls[key], grupos);
        })
        break;

      case TipoComponenteAppEnum.GRUPOS_MULTIVALORADOS:

        // Itera os controls/forms do FormGroup
        const formGroupArray = auxForm as FormArray;

        Object.keys(formGroupArray.controls).forEach(key => {

          if (auxForm instanceof FormArray) {

            const labelParent = metaDados.label + ' ' + (+key + 1);

            grupoConclusaoArray = grupos?.find(g => {
              return g.label == labelParent;
            })?.campos;

            // Caso o grupo não estiver no map, instancia e adiciona no map
            if (!grupoConclusaoArray) {
              grupoConclusaoArray = []
              grupoConclusaoKey = new GrupoConclusao(labelParent, metaDados.ordem, metaDados.tipoLayout, grupoConclusaoArray);
              grupos.push(grupoConclusaoKey);
            }

          }

          this.gerarGruposConclusao(grupoConclusaoKey, formGroupArray.get(key), grupos);
        })
        break;

      case TipoComponenteAppEnum.PASSO:

        // Caso for Passo/Step itera-se os controls/forms filhos
        const auxFormGroupStep = auxForm as FormGroup;
        Object.keys(auxFormGroupStep.controls).forEach(key => {
          this.gerarGruposConclusao(null, auxFormGroupStep.controls[key], grupos);
        })
        break;

      default:

    }

  }

  public get StatusEnum() {
    return StatusEnum;
  }

  /*
    Método que adiciona os campos no array de grupos para o componente de conclusão
  */
  public addCampoConclusao(metaDados: FormControlMetadados, grupoConclusaoArray: CampoConclusao[], auxForm: AbstractControl<any, any>) {

    const tipoComponente = metaDados.tipoComponente;
    const multiValorado = metaDados.multiValorado;
    const nome = metaDados.nome;
    const label = metaDados.label;
    const obrigatorio = metaDados.obrigatorio;
    let value;

    if (tipoComponente == CampoEnum.file && auxForm.value) {
      const valueFile = (auxForm.value.name as string);
      const index = valueFile.lastIndexOf('\\');
      value = valueFile.slice(index + 1, valueFile.length);

      this.formData.append("files", auxForm.value, value);

    } else if (tipoComponente == CampoEnum.select) {
      value = auxForm.value.valor;
    } else {
      value = auxForm.value;
    }

    // Tratativa para campos do tipo Calendar
    if (moment.isMoment(value)) {
      value = moment(value).format(this.dateFormat);
    }

    let text = '';
    if (tipoComponente == CampoEnum.a_href) {
      value = metaDados.valor;
    } else if (isInputTipo(tipoComponente)) {

      const valueLowerCase = label.toLowerCase();
      if (valueLowerCase.includes('cpf')) {
        text = formatCPF(value);
      } else if (valueLowerCase.includes('cnpj')) {
        text = formatCNPJ(value);
      } else if (valueLowerCase.includes('telefone')) {
        text = formatTelefone(value);
      } else if (valueLowerCase.includes('celular')) {
        text = formatCelular(value);
      } else if (valueLowerCase.includes('cep')) {
        text = formatCEP(value);
      }
    }

    let campoConclusao = null;
    let valorConclusao = new ValorConclusao(value, text);
    if (multiValorado) {

      // Checa se o CampoConclusao está no array de valores
      campoConclusao = grupoConclusaoArray.find(campoConclusaoArray => {
        return campoConclusaoArray.label == label;
      })

      // Se ele já foi inserido anteriormente adiciona no array de valores e sai da iteração
      if (campoConclusao && value) {
        campoConclusao.valor.push(valorConclusao);
        return;
      }

    }

    // Se ele não for multivalorado ou se não foi iniciado previamente , instancia o campo conclusão
    // adicionando-o ao array de valores
    campoConclusao = new CampoConclusao(nome, label, valorConclusao ? [valorConclusao] : [], tipoComponente, obrigatorio);
    grupoConclusaoArray.push(campoConclusao);

  }

  confirmarReinicializacao() {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      disableClose: false,
      scrollStrategy: new NoopScrollStrategy()
    });

    this.dialogRef.componentInstance.alertMessage = "Todos os dados preenchidos até o momento serão perdidos.";
    this.dialogRef.componentInstance.confirmMessage = " Deseja realmente prosseguir com a reinicialização do processo de criação do Requerimento?";

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/abertura', { outlets: { 'abertura': ['inicio'] } }]);
      }

      this.dialogRef = null;
    });
  }

}
