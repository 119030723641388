import { Component } from '@angular/core';
import { AuthenticationService } from './core/services/authentication.service';
import { UserResponse } from './core/models/authentication/responses/user-response';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  userResponse?: UserResponse | null;

  constructor(private authenticationService: AuthenticationService) {
      this.authenticationService.userResponse.subscribe(x => this.userResponse = x);
  }

  logout() {
      this.authenticationService.logout();
  }
  
  title = 'Entrar';
}
